import React from 'react'
import { Row, Col } from 'antd';
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import '../styles/han_taste_food.less';

const topBannerObj = {
  bgImg: 'han-taste-banner1.png',
  title: '汉味精彩美食',
  info: '尝遍千般滋味，道道都是美味',
}
const foodTypeArr1 = [
  {img: 'han-taste-ad7.png',title:'炒饭炒面',link:'#'},
  {img: 'han-taste-ad8.png',title:'汉味小吃',link:'/han_taste_snacks'},
  {img: 'han-taste-ad9.png',title:'香汁米饭',link:'#'},
  {img: 'han-taste-ad10.png',title:'妈妈砂锅饭',link:'#'},
]
const foodTypeArr2 = [
  {img: 'han-taste-ad1.jpg',title:'小碗菜'},
  {img: 'han-taste-ad2.jpg',title:'粥品'},
  {img: 'han-taste-ad3.jpg',title:'汤品'},
  {img: 'han-taste-ad4.jpg',title:'饮品'},
]
class HanTasteFood extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		}
	}
	componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#fff";
	}
	render() {
	  return (
      <div className='han-taste-food' id='han-taste-food'>
        <HeadModle value='2' keyValue='setting:2_2'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="top-info">
            <div className="frame-div clear">
              <div className="left-frame fl-l box-shadow">
                <div className="image-div">
                  <img src={require('../images/han-taste-ad5.png')} alt=""/>
                </div>
                <div className="words-div">
                  <div className="title">传承与创新</div>
                  <div className="info">石磨芝麻酱热干面系列</div>
                </div>
              </div>
              <div className="right-frame fl-r box-shadow">
                <a href="/features_surface">
                  <div className="image-div">
                    <img src={require('../images/han-taste-ad6.png')} alt=""/>
                  </div>
                  <div className="words-div">
                    <div className="title">特色粉面</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="food-type">
            <div className="frame-div">
              <Row>
                {foodTypeArr1.map((list,index)=>{
                  return (
                    <Col span={12} key={index} className="col-frame">
                      <a href={list.link}>
                        <div className="col-div box-shadow">
                          <div className="words-div">
                            <div className="title-div">{list.title}</div>
                          </div>
                          <div className="image-div">
                            <img src={require('../images/' + list.img)} alt=""/>
                          </div>
                        </div>
                      </a>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
          <div className="food-type2">
            <div className="frame-div">
              <Row>
                {foodTypeArr2.map((list,index)=>{
                  return (
                    <Col span={6} key={index} className="col-frame">
                      <div className="col-div box-shadow">
                        <div className="image-div">
                          <img src={require('../images/' + list.img)} alt=""/>
                        </div>
                        <div className="words-div">{list.title}</div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}

export default HanTasteFood
