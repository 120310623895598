import React from 'react'
import $$ from 'cmn-utils';
import { Row, Col } from 'antd';
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import '../styles/recruitment.less';

const topBannerObj = {
  bgImg: 'recruitment-banner1.png',
  title: '招兵买马',
  info: '加入常青麦香园，前景光亮',
}
// const positionInfoList = [
//   {name:'品控专员',salary:'3000',experience:'2年',address:'武汉市常青花园',educationalBackground:'中专',endDate:'2016-12-31',peopleNum:1,workNature:'服务',jpbCategory:'组长',jobDescribe:'1、生产过程控制，监督生产人员按照操作流程进行操作； <br/>2、对成品进行抽检，检验出具报告； <br/>3、上级交代的其他事项。',qualifications:'1、大专及以上学历，食品检验、加工相关专业毕业； <br/>2、一年以上相关工作经验，持食品检验工证； <br/>3、工作责任心强，实事求是，对数据敏感； <br/>4、有团队协作精神及服务精神。 ',email:'hr@hr@cqmxy.cn'},
//   {name:'品控专员',salary:'3000',experience:'2年',address:'武汉市常青花园',educationalBackground:'中专',endDate:'2016-12-31',peopleNum:1,workNature:'服务',jpbCategory:'组长',jobDescribe:'1、生产过程控制，监督生产人员按照操作流程进行操作； <br/>2、对成品进行抽检，检验出具报告； <br/>3、上级交代的其他事项。',qualifications:'1、大专及以上学历，食品检验、加工相关专业毕业； <br/>2、一年以上相关工作经验，持食品检验工证； <br/>3、工作责任心强，实事求是，对数据敏感； <br/>4、有团队协作精神及服务精神。 <br/>5、工作责任心强，实事求是，对数据敏感； <br/>6、有团队协作精神及服务精神。 ',email:'hr@hr@cqmxy.cn'},
// ]
// const requireArr = [
//   {img:'recruitment-ad1.png',title:'常青麦香园企业价值观',smallTitle:'',info:'客户：客户第<br/>团队：团队协作、传承创新<br/>个人：诚信、善良、坚韧、持续奋斗 '},
//   {img:'recruitment-ad2.png',title:'常青麦香园员工特质',smallTitle:'诚信、创新、谦虚、勤奋、激情、与人为善、责任感',info:'海底捞始终秉承“服务至上、顾客至上”的理念，以创新为核心，改变传统的标准化、单一化的服务，提倡个性化的特色服务，将用心服务作为基本理念，致力于为顾客提供“贴心、温心、舒心”的服务； 在管理上，倡导双手改变命运的价值观，为员工创建公平公正的工作环境，实施人性化和亲情化的管理模式，提升员工价值。'},
// ]

class Recruitment extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      positionInfoList: [],
    }
	}
  componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
    $$.get('api/home/recruit/search',{username:this.state.name, mobile:this.state.phone, email:this.state.email},{
      credentials:'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }, 
    })
    .then(data => {
      if(data.ret === 200){
        this.setState({
          positionInfoList: data.data.list,
        });
      }
    })
  }
  render() {
    return(
      <div className='recruitment' id='recruitment'>
        <HeadModle value='5' keyValue='setting:5_2'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="top-title">
            <div className="title-frame"><div className="title">在武汉 更多的人选择到常青麦香园工作</div></div>
            <div className="title-info">离家近&nbsp;&nbsp;&nbsp;∣&nbsp;&nbsp;&nbsp;工资高&nbsp;&nbsp;&nbsp;∣&nbsp;&nbsp;&nbsp;升职快&nbsp;&nbsp;&nbsp;∣&nbsp;&nbsp;&nbsp;福利多&nbsp;&nbsp;&nbsp;∣&nbsp;&nbsp;&nbsp;能创业</div>
          </div>
          <div className="development-path">
            <div className="path">
              <img src={require('../images/recruitment-img1.png')} alt=""/>
            </div>
            <div className="path">
              <img src={require('../images/recruitment-img2.png')} alt=""/>
            </div>
          </div>
          <div className="main-frame">
            <div className="frame-div">
              <Row>
                {this.state.positionInfoList.map((list,index)=>{
                  return (
                    <Col span={12} className='col-frame' key={index}>
                      <div className="col-div">
                        <div className="head-div">职位名称<span>{list.position_name}</span></div>
                        <div className="col-content">
                          <div className="main-content scrollbar">
                            <div className="top-table">
                              <table>
                                <tbody>
                                  <tr>
                                    <td>职位月薪：{list.salary}</td>
                                    <td>工作经验：{list.experience}</td>
                                  </tr>
                                  <tr>
                                    <td>工作地点：{list.address}</td>
                                    <td>最低学历：{list.education}</td>
                                  </tr>
                                  <tr>
                                    <td>截止日期：{list.end_date}</td>
                                    <td>招聘人数：{list.recruit_limit}</td>
                                  </tr>
                                  <tr>
                                    <td>工作性质：{list.job_nature}</td>
                                    <td>职位类别：{list.position_cat}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="describe-frame">
                              <div className="title-div">任职资格</div>
                              <div className="info-div" dangerouslySetInnerHTML={{__html: list.qualification}}></div>
                            </div>
                            <div className="describe-frame">
                              <div className="title-div">后勤及休息</div>
                              <div className="info-div" dangerouslySetInnerHTML={{__html: list.postion_desc}}></div>
                            </div>
                          </div>
                          <div className="emil-div">
                            <a href={"mailto:" + list.link} className="email">立即申请</a>
                            <span>注：点击按钮或请将邮件发送至：{list.link}</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
              {/* <div className="require-list">
                <ul>
                  {requireArr.map((list,index)=>{
                    return (
                      <li key={index}>
                        <div className="li-main">
                          <div className="yellow-div"></div>
                          <div className="image-div">
                            <img src={require('../images/' + list.img)} alt=""/>
                          </div>
                          <div className="words-frame">
                            <div className="title-div">{list.title}</div>
                            <div className={!!list.smallTitle ? "small-title" : "small-title hide"}>{list.smallTitle}</div>
                            <div className="info-div" dangerouslySetInnerHTML={{__html:list.info}}></div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div> */}
            </div>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}

export default Recruitment
