import React from 'react'
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import '../styles/business_philosophy.less';

const topBannerObj = {
  bgImg: 'business-philosophy-banner1.png',
  title: '经营理念',
  info: '湖北常青麦香园餐饮管理有限公司',
}

class BusinessPhilosophy extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		}
  }
  componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
  }
  render() {
    return(
      <div className='business-philosophy' id='business-philosophy'>
        <HeadModle value='1' keyValue='setting:1_2'></HeadModle>
        <BannerModule value={topBannerObj}></BannerModule>
        <div className="main-frame">
          <div className="frame-div">
            <div className="portrait-div">
              <img src={require('../images/business-philosophy-img1.png')} alt=""/>
            </div>
            <div className="image-div">
              <img src={require('../images/business-philosophy-ad1.png')} alt=""/>
            </div>
            <div className="words-div">
              <div className="philosophy">
                <div className="title">企业愿景</div>
                <div className="info">以热干面为核心产品的中国汉味小吃快餐连锁领导品牌</div>
              </div>
              <div className="philosophy">
                <div className="title">企业使命</div>
                <div className="info">传统汉味小吃文化的传播者与创新者</div>
              </div>
              <div className="philosophy">
                <div className="title">企业价值观</div>
                <div className="info">
                  <p><span>客户：</span>客户第一 </p>
                  <p><span>团队：</span>团队协作、传承创新 </p>
                  <p><span>个人：</span>诚信、善良、坚韧、持续奋斗</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterModule></FooterModule>
        <RightSuspension></RightSuspension>
      </div>
    );
  }
}

export default BusinessPhilosophy
