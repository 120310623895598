import React from 'react'
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import { Player } from 'video-react';
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'
import '../styles/company_profile.less';

const topBannerObj = {
  bgImg: 'company-profile-banner1.png',
  title: '公司简介',
  info: '湖北常青麦香园餐饮管理有限公司',
}

class CompanyProfile extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		}
  }
  meaningShow(){
    document.getElementById('video-frame').style.display = 'flex';
    document.getElementById('body-frame').className = 'blur';
    this.refs.player.play();
  }
  videoHide(){
    document.getElementById('video-frame').style.display = 'none';
    document.getElementById('body-frame').className = ' ';
    this.refs.player.pause();
  }
  componentWillUnmount() {
		if (this.swiper) { // 销毁swiper
			this.swiper.destroy();
		}
	}
  componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#fff";
    if(document.documentElement.clientWidth < 480){
      const scrollLeft = document.documentElement.clientWidth * 40 / 750;
      document.getElementById('company-profile').querySelector(".top-introduce .bottom-list .list-div").scrollLeft = scrollLeft * 11;
    }
    if(this.swiper){
			this.swiper.slideTo(0, 0);
			this.swiper.destroy();
			this.swiper = null;
		}
		this.swiper = new Swiper('.banner-swiper', {
			loop:true,
			pagination: {
				el: '.banner-pagination',
				// type: 'fraction',
				clickable: true,
      },
      autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
		});
  }
  render() {
    return(
      <div className='company-profile' id='company-profile'>
        <HeadModle value='1' keyValue='setting:1_1'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="top-introduce">
            <div className="top-main">
              <div className="top-frame">
                <div className="image-div">
                  <div className="img">
                    <img src={require('../images/company-profile-ad1.jpg')} alt=""/>
                  </div>
                  <div className="meaning-div" onClick={()=>this.meaningShow()}>
                    查看相关视频<img src={require('../images/company-share-arrow.png')} alt=""/>
                  </div>
                </div>
                <div className="title-div">打造以热干面为核心的中国汉味小吃快餐连锁领导品牌</div>
                <div className="info-div">
                  <p>湖北常青麦香园餐饮管理有限公司是中华名小吃武汉热干面的连锁品牌经营商之一。2012年，由蔡林记热干面第三代嫡传人—李亚娟女士创办。是<b>集热干面智能研发生产、汉味小餐饮技术培训、餐饮创业咨询、供应链集采、快餐连锁品牌经营</b>为一体的综合性餐饮集团。着力建设“从生产到销售，从培训到创业”的热干面产业闭环，现已拥有员工1000余人、加盟商6000余人。 </p>
                  <p>公司历经6年发展，开创性地将全国“十大名面之一”的热干面与独具特色的汉味小吃完美结合，为顾客提供方便快捷、营养美味的早餐食品，让每个都市人的早晨都能伴随着的热气腾腾的面条和芝麻酱的香气，开始元气满满的一天。</p>
                  <p>截至2018，品牌覆盖全国60余个主要城市的商业地段，在<b>湖北8+1城市圈、湖南、江西、河南、山西、四川、广州</b>等省份<b>1000</b>多家分店，日均接待顾客<b>70多万</b>人次，日均销售热干面近60万碗。凭借良好的口碑和声誉，一跃成为华中地区家喻户晓的早餐连锁品牌，是中国热干面跨区域连锁发展品牌。</p>
                </div>
              </div>
              <div className="bottom-list">
                <div className="list-div">
                  <ul className="clear">
                    <li className="fl-l">
                      <div className="li-mian">
                        <div className="num-div">1000+</div>
                        <div className="words-div">常青麦香园<br/>现拥有员工</div>
                      </div>
                    </li>
                    <li className="fl-l">
                      <div className="li-mian">
                        <div className="num-div">6000+</div>
                        <div className="words-div">加盟商人数</div>
                      </div>
                    </li>
                    <li className="fl-l">
                      <div className="li-mian">
                        <div className="num-div">60+</div>
                        <div className="words-div">主要城市商业地段</div>
                      </div>
                    </li>
                    <li className="fl-l">
                      <div className="li-mian">
                        <div className="num-div">900+</div>
                        <div className="words-div">全国分店</div>
                      </div>
                    </li>
                    <li className="fl-l">
                      <div className="li-mian">
                        <div className="num-div">60万+</div>
                        <div className="words-div">日接待顾客人数</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="history-frame">
            <div className="history-head">
              <div className="frame-div">常青麦香园发展历史</div>
            </div>
            <div className="history-list">
              <ul className="clear">
                <li>
                  <div className="li-main">
                    <div className="year-div">1993</div>
                    <div className="info-div">
                      <p>第一家常青麦香园门店开业</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="li-main">
                    <div className="year-div">2012</div>
                    <div className="info-div">
                      <p><b>首家加盟店妙墩路店开业.热干面自动生产线研发成功</b></p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="li-main">
                    <div className="year-div">2013</div>
                    <div className="info-div">
                      <p>VI升级，产品升级，新华路开业面积100+</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="li-main">
                    <div className="year-div">2014</div>
                    <div className="info-div">
                      <p>大型综合体后湖开业，产品多元化面积200+市外加盟店监利开业，品牌走出武汉.门店超过100家</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="li-main">
                    <div className="year-div">2015</div>
                    <div className="info-div">
                      <p>品牌第三代形象升级，门店店数近300家，其中直营店数20家</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="li-main">
                    <div className="year-div">2016</div>
                    <div className="info-div">
                      <p><b>李亚娟女士获中国烹饪艺术家称号<br/>门店近600家，直营30+家</b></p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="li-main">
                    <div className="year-div">2017</div>
                    <div className="info-div">
                      <p><b>智慧鲜食工厂正式投产，日产面条10万斤，全新确立集团品牌战略</b></p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="li-main">
                    <div className="year-div">2018</div>
                    <div className="info-div">
                      <p>突破1000家门店！</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="top-introduce business-philosophy">
            <div className="top-main">
              <div className="top-frame">
                <div className="image-div">
                  <img src={require('../images/business-philosophy-ad1.jpg')} alt=""/>
                </div>
                <div className="title-div">经营理念</div>
                <div className="words-div">
                  <div className="philosophy">
                    <div className="title">企业愿景</div>
                    <div className="info">以热干面为核心产品的中国汉味小吃快餐连锁领导品牌</div>
                  </div>
                  <div className="philosophy">
                    <div className="title">企业使命</div>
                    <div className="info">传统汉味小吃文化的传播者与创新者</div>
                  </div>
                  <div className="philosophy">
                    <div className="title">企业价值观</div>
                    <div className="info">
                      <p><span>客户：</span>客户第一 </p>
                      <p><span>团队：</span>团队协作、传承创新 </p>
                      <p><span>个人：</span>诚信、善良、坚韧、持续奋斗</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-banner">
            <div className="bg-div">
              <img src={require('../images/company-profile-bg.jpg')} alt=""/>
            </div>
            <div className="title-frame">
              <div className="frame-div">常青麦香园荣誉</div>
            </div>
            <div className="swiper-container banner-swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="info-div">
                    <p><b>2012年9月湖北省首批食文化技艺传承人</b></p>
                    <p>（李亚娟）</p>  
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="info-div">
                    <p><b>2012年9月湖北省首批食文化知名食品</b></p>
                    <p>（武汉李氏麦香园餐饮管理有限公司）</p>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="info-div">
                    <p><b>2012年9月湖北省首届食文化技艺传承人 热干面发明人第三代嫡传</b></p>
                    <p>（李亚娟）</p>  
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="info-div">
                    <p><b>2012年11月中国食文化传承与创新优秀企业</b></p>
                    <p>（武汉李氏麦香园餐饮管理有限公司）</p>  
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="info-div">
                    <p><b>2014年9月湖北省十佳食文化品牌称号</b></p>
                    <p>（常青麦香园生鲜热干面武汉李氏麦香园餐饮管理有限公司）</p>  
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="info-div">
                    <p><b>2015年9月湖北省食文化优秀企业称号</b></p>
                    <p>（武汉李氏麦香园餐饮管理有限公司）</p>  
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="info-div">
                    <p><b>2016年3月10日中国烹饪艺术家</b></p>
                    <p>（李亚娟）</p>  
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="info-div">
                    <p><b>2017年元月执行会长单位</b></p>
                    <p>（武汉李氏麦香园餐饮管理有限公司）</p>  
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="info-div">
                    <p><b>2018年4月2017年度中国早餐经营优秀企业</b></p>
                    <p>（武汉李氏麦香园餐饮管理有限公司）</p>  
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="info-div">
                    <p><b>2018年5月9日2018中国餐饮好品牌</b></p>
                    <p>（武汉李氏麦香园餐饮管理有限公司）</p>  
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="info-div">
                    <p><b>2018年5月9日2018中国餐饮优秀企业家</b></p>
                    <p>（李亚娟）</p>  
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="info-div">
                    <p><b>2018年10月 注册中国烹饪大师</b></p>
                    <p>（李亚娟）</p>  
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="info-div">
                    <p><b>2019年3月盟享加.第51届中国特许加盟展（武汉）</b></p>
                    <p>（武汉李氏麦香园餐饮管理有限公司）</p>  
                  </div>
                </div>
              </div>
              <div className="swiper-pagination banner-pagination"></div>
            </div>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
        <div id="video-frame">
          <div className="video-wrapper">
            <div className="close-div" onClick={()=>this.videoHide()}>
              <span className="close-span">×</span>
            </div>
            <Player id="player" ref="player" videoId="video-1" autoPlay={false} muted={false} loop={true} controls={false} poster={"../images/index-banner.png"}>
              <source src={"https://maixiangyuan-image.oss-cn-beijing.aliyuncs.com/mendian.mp4"}/>
            </Player>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyProfile
