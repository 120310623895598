import React from 'react'
import $$ from 'cmn-utils';
import { Row, Col } from 'antd';
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import { Player } from 'video-react';
import SwiperFreshFactory from '../components/SwiperFreshFactory';
import '../styles/professional_teaching.less';

const topBannerObj = {
  bgImg: 'professional-teaching-banner1.png',
  title: '专业教学',
  info: '中国第一所武汉热干面培训学校',
}
const foodTypeArr2 = [
  {img: 'professional-teaching-ad2.png',title:'精致汉味小吃'},
  {img: 'professional-teaching-ad3.png',title:'暖心养生粥品'},
  {img: 'professional-teaching-ad4.png',title:'经典西式烘焙'},
  {img: 'professional-teaching-ad5.png',title:'潮流时尚饮品'},
  {img: 'professional-teaching-ad6.png',title:'时令特色菜品'},
]

class ProfessionalTeaching extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      bannerArr: [],
		}
  }
  meaningShow(){
    document.getElementById('video-frame').style.display = 'flex';
    document.getElementById('body-frame').className = 'blur';
    this.refs.player.play();
  }
  videoHide(){
    document.getElementById('video-frame').style.display = 'none';
    document.getElementById('body-frame').className = ' ';
    this.refs.player.pause();
  }
  componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
    $$.post('api/home/banner/search',{type:5},{
      credentials:'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }, 
    })
    .then(data => {
      this.setState({
        bannerArr: data.data.list,
      });
    })
  }
  render() {
    return(
      <div className='professional-teaching' id='professional-teaching'>
        <HeadModle value='3' keyValue='setting:3_2'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="teachers-frame">
            <div className="frame-div clear">
              <div className="left-image fl-l">
                  <div className="img">
                    <img src={require('../images/professional-teaching-ad1.png')} alt=""/>
                  </div>
                  <div className="meaning-div" onClick={()=>this.meaningShow()}>
                    查看相关视频<img src={require('../images/company-share-arrow.png')} alt=""/>
                  </div>
              </div>
              <div className="right-words fl-l">
                <div className="title">师资力量</div>
                <div className="list-div">
                  <ul>
                    <li>餐饮行业20多年丰富经验行业泰斗名师讲堂</li>
                    <li>铁三角创新研发团队专业指导</li>
                    <li>10逾名技术骨干十年以上产品制作培训经验倾心授课</li>
                    <li>拥有千店实操管理经验的精英管理团队为创业保驾护航</li>
                    <li>贴心后勤、高效追踪团队等师资力量让您学习生活全程无忧</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-div">
            <SwiperFreshFactory value={this.state.bannerArr}></SwiperFreshFactory>
          </div>
          <div className="tutorial-frame">
            <div className="frame-top">教学课程<span>传统石磨芝麻酱热干面为主打产品， 8大类30余款特色粉面</span></div>
            <div className="frame-div">
              <Row>
                {foodTypeArr2.map((list,index)=>{
                  return (
                    <Col span={6} key={index} className="col-frame">
                      <div className="col-div">
                        <div className="image-div">
                          <img src={require('../images/' + list.img)} alt=""/>
                        </div>
                        <div className="words-div">{list.title}</div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
        <div id="video-frame">
          <div className="video-wrapper">
            <div className="close-div" onClick={()=>this.videoHide()}>
              <span className="close-span">×</span>
            </div>
            <Player id="player" ref="player" videoId="video-1" autoPlay={false} muted={false} loop={true} controls={false} poster={"../images/index-banner.png"}>
              <source src={"https://maixiangyuan-image.oss-cn-beijing.aliyuncs.com/%E5%B8%B8%E9%9D%92%E9%BA%A6%E9%A6%99%E5%9B%AD%E5%95%86%E5%AD%A6%E9%99%A2.mp4"}/>
            </Player>
          </div>
        </div>
      </div>
    )
  }
}

export default ProfessionalTeaching
