import React from 'react'
import $$ from 'cmn-utils';
import { Pagination } from 'antd';
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import '../styles/partner_story.less';

const newsArr = [
  {img:'partner-story-ad1.jpg',name:[{name:'黄培',age:29,}],store:'徐东才茂店',info:'<p>黄培，2014年7月份加盟常青麦香园，之前是在武广化妆品柜台做化妆品销售工作。2016年 开了徐东的店子，总投入40万，2017年3月份刚好一年，已全部回本。 </p><p>从一个什么都不懂的小女孩，到慢慢学会生意之道，黄培成熟了很多，妈妈不用在家打零工，爸爸不用站脚手架，弟弟也告别了工厂一个月3000块钱的工资，在武汉也买了房。 </p><p>“等徐东店稳定了，明年还想再开一家店，甚至每年一家，希望常青麦香园这个品牌越来越好。”  </p>',link:'/partner_story'},
  {img:'partner-story-ad2.jpg',name:[{name:'李红波',age:40,},{name:'李梅',age:42,}],store:'河南加盟店',info:'<p>李红波/李梅，来自河南驻马店正阳县，14年加盟常青麦香园，之前是做火锅，酒店。第一家店大概做了七八个月就回本，第二家店大概投资了50万，差不多一年回本。现在夫妻两人每天忙碌又充实，开一个店，守好一个店，最起码有信心，以后可以给孩子提供一个更好的学习环境，让他去大城市上学，希望常青麦香园越走越远，越做越大。 </p>',link:'/partner_story'},
  {img:'partner-story-ad3.jpg',name:[{name:'程罡',age:38,}],store:'黄石加盟店',info:'<p>程罡，来自黄石，2015年5月份加盟常青麦香园。之前是做钢材。第一家店（黄石）花湖店是2015年5月份开业，投入的资金是将近40万，花了将近1年的时间，成本就回来了。 </p><p>“我在这个碰巧的时间，碰巧的机遇，我接触了常青麦香园，我觉得我这个转型非常成功，我很欣慰，我们会一直跟随着公司的步伐前进，以后的前景是非常非常好的</p>',link:'/partner_story'},
  {img:'partner-story-ad4.jpg',name:[{name:'彭钰杰',age:29,},{name:'吴皓',age:28,}],store:'磨山港湾店',info:'<p>彭钰杰/吴皓，14年加盟常青麦香园。 </p><p>“感谢李总跟徐总，改变了我们的生活轨迹，什么买房啊，买车啊，都是靠常青麦香园，没有一个早餐店在武汉可以做到常青麦香园的今天！年轻人嘛，要有一股冲劲，才有可能成功。”  </p>',link:'/partner_story'},
]
const topBannerObj = {
  bgImg: 'partner-story-banner1.png',
  title: '合伙人故事',
  info: '成功的合作一定是理念上的高度认同',
}
class PartnerStory extends React.Component {
  constructor(props){
		super(props);
		this.state = {
      labelCurrent: 0,
      newsArr:[],
      totalcount: 0,
		}
  }
  labelClick(i){
    this.setState({
      labelCurrent: i,
    });
  }
  onChange(page, pageSize){
    console.log(page, pageSize);
    this.searchFun(page, pageSize);
  }
  searchFun(page=1,pagenum=10){
    $$.post("api/home/news/search",{page,pagenum},{
      credentials:'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }, 
    })
    .then(data => {
      console.log(data);
      this.setState({
        newsArr: data.data.list,
        totalcount: data.data.totalcount,
      });
    })
  }
	componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
    this.searchFun();
	}
  render() {
    return (
      <div className='partner-story' id='partner-story'>
        <HeadModle value='4' keyValue='setting:4_2'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="news-frame">
            <div className="frame-div">
              <div className="row-div">
                <ul>
                  {
                    newsArr.map((list,index)=>{
                      return (
                        <li key={index} id={index+1}>
                          <div className="li-main clear">
                            <div className="image-div fl-l">
                              <img src={require('../images/' + list.img)} alt=""/>
                              <div className="link-div">
                                <a href={list.link}>
                                  <img src={require('../images/partner-story-ico1.png')} alt=""/>
                                </a>
                              </div>
                            </div>
                            <div className="words-div">
                              <div className="words-top clear">
                                <div className="people-frame clear fl-l">
                                  {list.name.map((list,index)=>{
                                    return (
                                      <div key={index} className="people fl-l clear">
                                        <div className="name fl-l">{list.name}</div>
                                        <div className="age-div fl-l">{list.age}岁</div>
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="store-div fl-r">{list.store}</div>
                              </div>
                              <div className="info-frame scrollbar fl-r" dangerouslySetInnerHTML={{__html: list.info}}></div>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  }
                </ul>
              </div>
            </div>
            {/* <div className="pagination">
              <Pagination onChange={(page, pageSize)=>this.onChange(page, pageSize)} total={this.state.totalcount} defaultCurrent={1} />
            </div> */}
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    );
  }
}

export default PartnerStory
