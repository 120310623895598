import React from 'react'
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import '../styles/cooking_salon.less';

const topBannerObj = {
  bgImg: 'cooking-salon-banner1.png',
  title: '烹饪沙龙',
  info: '中国第一所武汉热干面培训学校',
}

class CookingSalon extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		}
	}
  componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
  }
  render() {
    return(
      <div className='cooking-salon' id='cooking-salon'>
        <HeadModle value='3' keyValue='setting:3_3'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="teachers-frame">
            <div className="frame-div clear">
              <div className="left-image fl-l">
                <img src={require('../images/cooking-salon-ad1.png')} alt=""/>
              </div>
              <div className="right-words fl-l">
                <div className="title">行业信息</div>
                <div className="list-div scrollbar">
                  <p>人对于食物的选择早已摆脱了对先天本能的依赖，主要凭教养获得的后天经验，包括自然的、生理的 、心理的、习俗的诸多因素，其核心则是对味的实用和审美的选择。烹饪艺术所指的味觉艺术，是指审美对象广义的味觉。广义的味觉错综复杂。人们感受的馔肴的滋味、气味，包括单纯的咸、甜、酸、苦、辛和千变万化的复合味，属化学味觉;馔肴的软硬度、粘性、弹性、凝结性及粉状、粒状、块状、片状、泡沫状等外观形态及馔肴的含水量、油性、脂性等触觉特性，属物理味觉;由人的年龄、健康、情绪、职业，以及进餐环境、色彩、音响、光线和饮食习俗而形成的对馔肴的感觉，属心理味觉。中国烹饪的烹与调，正是面对错综复杂的味感现象，运用调味物质材料，以烹饪原料和水为载体，表现味的个性，进行味的组合，并结合人们心理味觉的需要，巧妙地反映味外之味和乡情乡味，来满足人们生理的、心理的需要，展示实用与审美相结合的烹饪艺术核心的味觉艺术。烹饪技术是实现味觉艺术的手段。其主旨乃是"有味使之出，无味使之入"。</p>
                </div>
              </div>
            </div>
          </div>
          <div className="main-frame learn">
            <div className="frame-div">
              <div className="head-div">交流学习</div>
              <div className="list-frame scrollbar">
                <div className="list-div">
                  <ul>
                    <li>
                      <div className="li-main">
                        <div className="image">
                          <img src={require('../images/cooking-salon-ad2.jpg')} alt=""/>
                        </div>
                        <div className="words">2018年 东方美食参观照片</div>
                      </div>
                    </li>
                    <li>
                      <div className="li-main">
                        <div className="image">
                          <img src={require('../images/cooking-salon-ad3.jpg')} alt=""/>
                        </div>
                        <div className="words">2018年 东方美食参观照片</div>
                      </div>
                    </li>
                    <li>
                      <div className="li-main">
                        <div className="image">
                          <img src={require('../images/cooking-salon-ad2.jpg')} alt=""/>
                        </div>
                        <div className="words">2018年 东方美食参观照片</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="main-frame">
            <div className="frame-div">
              <div className="head-div">成品展示</div>
              <div className="list-frame scrollbar">
                <div className="list-div">
                  <ul>
                    <li>
                      <div className="li-main">
                        <div className="image">
                          <img src={require('../images/cooking-salon-ad4.jpg')} alt=""/>
                        </div>
                        <div className="words">石墨酱热干面</div>
                      </div>
                    </li>
                    <li>
                      <div className="li-main">
                        <div className="image">
                          <img src={require('../images/cooking-salon-ad5.jpg')} alt=""/>
                        </div>
                        <div className="words">特色粉面-炸酱面</div>
                      </div>
                    </li>
                    <li>
                      <div className="li-main">
                        <div className="image">
                          <img src={require('../images/cooking-salon-ad4.jpg')} alt=""/>
                        </div>
                        <div className="words">石墨酱热干面</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}

export default CookingSalon
