import React from 'react'
import { Route, Redirect } from 'react-router'
import { Switch, BrowserRouter } from 'react-router-dom'

import Home from 'routes/home'
import SchoolProfile from 'routes/school_profile'
import CompanyProfile from 'routes/company_profile'
import Introduce from 'routes/introduce'
import NewsActivity from 'routes/news_activity'
import FreshFactory from 'routes/fresh_factory'
import NewsDetails from 'routes/news_details'
import HotNoodles from 'routes/hot_noodles'
import HanTasteFood from 'routes/han_taste_food'
import FeaturesSurface from 'routes/features_surface'
import HanTasteSnacks from 'routes/han_taste_snacks'
import JoinInPolicy from 'routes/join_in_policy'
import StoreDisplay from 'routes/store_display'
import Cooperation from 'routes/cooperation'
import Recruitment from 'routes/recruitment'
import OnlineRegistration from 'routes/online_registration'
import BrandStory from 'routes/brand_story'
import Inherit from 'routes/inherit'
import Honor from 'routes/honor'
import BusinessPhilosophy from 'routes/business_philosophy'
import ProfessionalTeaching from 'routes/professional_teaching'
import CookingSalon from 'routes/cooking_salon'
import EntrepreneurialPlatform from 'routes/entrepreneurial_platform'
import PartnerStory from 'routes/partner_story'

const Routes = () => (
  <BrowserRouter>
    <div>
      <Route exact path="/" render={() => <Redirect to="/home" />} />
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/school_profile" component={SchoolProfile} />
        <Route path="/company_profile" component={CompanyProfile} />
        <Route path="/introduce" component={Introduce} />
        <Route path="/news_activity" component={NewsActivity} />
        <Route path="/fresh_factory" component={FreshFactory} />
        <Route path="/news_details" component={NewsDetails} />
        <Route path="/hot_noodles" component={HotNoodles} />
        <Route path="/han_taste_food" component={HanTasteFood} />
        <Route path="/features_surface" component={FeaturesSurface} />
        <Route path="/han_taste_snacks" component={HanTasteSnacks} />
        <Route path="/join_in_policy" component={JoinInPolicy} />
        <Route path="/store_display" component={StoreDisplay} />
        <Route path="/cooperation" component={Cooperation} />
        <Route path="/recruitment" component={Recruitment} />
        <Route path="/online_registration" component={OnlineRegistration} />
        <Route path="/brand_story" component={BrandStory} />
        <Route path="/inherit" component={Inherit} />
        <Route path="/honor" component={Honor} />
        <Route path="/business_philosophy" component={BusinessPhilosophy} />
        <Route path="/professional_teaching" component={ProfessionalTeaching} />
        <Route path="/cooking_salon" component={CookingSalon} />
        <Route path="/entrepreneurial_platform" component={EntrepreneurialPlatform} />
        <Route path="/partner_story" component={PartnerStory} />
      </Switch>
    </div>
  </BrowserRouter>
)

const App = () => (
  <Routes />
)

export default App
