import React from 'react'
import HeadModle from '../components/HeadModle'
import FooterModule from '../components/FooterModule'
import RightSuspension from '../components/RightSuspension'
import '../styles/honor.less'

class HanTasteFood extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		}
  }
  
	componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#fff";
  }
	render() {
	  return (
      <div className='honor' id='honor'>
        <HeadModle value='1' keyValue='setting:1_3'></HeadModle>
        <div className="detail-banner">
          <div className="bg-div"></div>
          <div className="words-div">
            <div className="title-div">卓越成果</div>
          </div>
        </div>
        <div className="honor-detail">
          <div className="detail-frame">
            <ul>
              <li>
                <div className="li-main">
                  <div className="image-div">
                    <img src={require('../images/honor-img1.png')} alt=""/>
                  </div>
                  <div className="words-frame">
                    <div className="words-div clear">
                      <div className="month-div fl-l">9月 •</div>
                      <div className="info-div fl-l">李亚娟同志在湖北省首届食文化名企名食名人评选活动中被评为“湖北省首批食文化技艺传承人”</div>
                    </div>
                    <div className="words-div clear">
                      <div className="month-div fl-l">9月 •</div>
                      <div className="info-div fl-l">常青麦香园热干面在湖北省首届食文化名企名食名人评选活动中被评为“湖北省首批食文化知名食品”</div>
                    </div>
                    <div className="words-div clear">
                      <div className="month-div fl-l">11月 •</div>
                      <div className="info-div fl-l">中国食文化研究会授予武汉李氏麦香园餐饮管理有限公司“中国食文化传承与创新优秀企业” </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="li-main">
                  <div className="image-div">
                    <img src={require('../images/honor-img2.png')} alt=""/>
                  </div>
                  <div className="words-frame">
                    <div className="words-div clear">
                      <div className="month-div fl-l">9月 •</div>
                      <div className="info-div fl-l">湖北省首届食文化节活动组委会、湖北省食文化研究会授予武汉李氏麦香园餐饮管理有限公司“常青麦香园”生鲜热千面“湖北省十佳食文化品牌称号” </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="li-main">
                  <div className="image-div">
                    <img src={require('../images/honor-img3.png')} alt=""/>
                  </div>
                  <div className="words-frame">
                    <div className="words-div clear">
                      <div className="month-div fl-l">1月 •</div>
                      <div className="info-div fl-l">湖北省食文化研究会评选武汉李氏麦香园餐饮管理有限公司为“执行会长单位” </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="li-main">
                  <div className="image-div">
                    <img src={require('../images/honor-img4.png')} alt=""/>
                  </div>
                  <div className="words-frame">
                    <div className="words-div clear">
                      <div className="month-div fl-l">4月 •</div>
                      <div className="info-div fl-l">中国烹饪协会评选武汉李氏麦香园餐饮管理有限公司为“2017年度中国早餐经营优秀企业” </div>
                    </div>
                    <div className="words-div clear">
                      <div className="month-div fl-l">5月 •</div>
                      <div className="info-div fl-l">武汉李氏麦香园餐饮管理有限公司李亚娟被评为“2018中国餐饮优秀企业家” </div>
                    </div>
                    <div className="words-div clear">
                      <div className="month-div fl-l">5月 •</div>
                      <div className="info-div fl-l">武汉李氏麦香园餐饮管理有限公司被评为“2018中国餐饮好品牌” </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <FooterModule></FooterModule>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}

export default HanTasteFood
