import React from 'react';
import '../styles/BannerModule.less';

class BannerModule extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		}
	}
	render() {
	  return (
			<div className="banner-frame" id="banner-frame">
				<div className="bg-div">
					<img src={require('../images/' + this.props.value.bgImg)} alt=""/>
				</div>
				<div className="words-div">
					<div className="title-div">{this.props.value.title}</div>
					<div className="info-div" dangerouslySetInnerHTML={{ __html: this.props.value.info }}></div>
				</div>
			</div>
		)
	}
}

export default BannerModule;