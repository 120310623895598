import React from 'react'
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import '../styles/fresh_factory.less';

const topBannerObj = {
  bgImg: 'fresh-factory-banner1.png',
  title: '智慧鲜食工厂',
  info: '开启武汉早餐行业新格局',
}
const processArr = [
  {img:'fresh-factory-img6.png',title:'工序一：甄选面粉',},
  {img:'fresh-factory-img7.png',title:'工序二：揉压面饼',},
  {img:'fresh-factory-img8.png',title:'工序三：切割面条',},
  {img:'fresh-factory-img9.png',title:'工序四：沸水煮开',},
  {img:'fresh-factory-img10.png',title:'工序五：蒸煮喷油',},
  {img:'fresh-factory-img11.png',title:'工序六：冷却晾干',},
  {img:'fresh-factory-img12.png',title:'工序七：根根担面',},
  {img:'fresh-factory-img13.png',title:'工序八：无菌包装',},
]
const standardArr = [
  {type:'标准化',img:'fresh-factory-ad2.png',title:'5层消毒，细菌无所遁形',info:'常青麦香园深知，食品安全是食品企业发展基石，将决定企业未来的道路 '},
  {type:'数字化',img:'fresh-factory-ad3.png',title:'“蒸”功夫，还原手工制面',info:'中央大厨房严格控管面条粗细、水温、担面的次数等细节，确保传统率口味绝不失真'},
  {type:'品质化',img:'fresh-factory-ad4.png',title:'精选大牌，确保千店一味',info:'食品连锁店味道统一并非易事，味道是食品连锁的核心竞争力 '},
  {type:'严格化',img:'fresh-factory-ad5.png',title:'检测严苛•360°保障安全',info:'我们拥有一群富有开拓精神的研发团队和手持多项技能证书的安全验化员、食品安全内审员 '},
]
class FreshFactory extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		}
	}
	componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
	}
	render() {
	  return (
      <div className='fresh-factory' id='fresh-factory'>
        <HeadModle value='1' keyValue='setting:1_4'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="top-introduction">
            <div className="frame-div">开启武汉早餐行业新格局</div>
            <div className="info-div">前瞻性布局·用工业化加速小餐饮巨变</div>
          </div>
          <div className="features-frame">
            <div className="frame-div">
              <ul className="clear">
                <li>
                  <div className="image-div">
                    <img src={require('../images/fresh-factory-img1.png')} alt=""/>
                  </div>
                </li>
                <li>
                  <div className="image-div">
                    <img src={require('../images/fresh-factory-img2.png')} alt=""/>
                  </div>
                </li>
                <li>
                  <div className="image-div">
                    <img src={require('../images/fresh-factory-img3.png')} alt=""/>
                  </div>
                </li>
                <li>
                  <div className="image-div">
                    <img src={require('../images/fresh-factory-img4.png')} alt=""/>
                  </div>
                </li>
                <li>
                  <div className="image-div">
                    <img src={require('../images/fresh-factory-img5.png')} alt=""/>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="process-frame">
            <div className="top-image">
              <img src={require('../images/fresh-factory-ad1.png')} alt=""/>
            </div>
            <div className="info-div">2016年斥巨资新建智慧鲜食工厂，工业园设有4条热干面自动化生产线 ，日产面条10万斤；能提供米粉、油炸制品、蒸类食品、米制品等其他食品半成品；同时，园区具备仓储及冷链物流配送能力，能为200公里范围内的门店提供所需产品。此举在行业内具有突破性、革命性的意义，这将改变了早餐行业传统的非标准化和效率低下的现状，解决了传统制造工艺中原料、生产、存储、配送等固有难题。 </div>
            <div className="list-div">
              <ul className="clear">
                {processArr.map((list,index)=>{
                  return (
                    <li key={index} className="fl-l">
                      <div className="li-main">
                        <div className="image">
                          <img src={require('../images/' + list.img)} alt=""/>
                        </div>
                        <div className="words">{list.title}</div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="standard-frame">
            <div className="list-frame">
              <ul className="clear">
                {standardArr.map((list,index)=>{
                  return (
                    <li key={index} className="fl-l">
                      <div className="li-main">
                        <div className="type-div">{list.type}</div>
                        <div className="image-div">
                          <img src={require('../images/' + list.img)} alt=""/>
                        </div>
                        <div className="words-div">
                          <div className="title-div">{list.title}</div>
                          <div className="info-div">{list.info}</div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="bottom-ad">
            <ul>
              <li>
                <div className="image-div">
                  <img src={require('../images/fresh-factory-ad6.png')} alt=""/>
                </div>
              </li>
              <li>
                <div className="image-div">
                  <img src={require('../images/fresh-factory-ad7.png')} alt=""/>
                </div>
              </li>
            </ul>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}

export default FreshFactory
