import React from 'react'
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'
import '../styles/join_in_policy.less';

const topBannerObj = {
  bgImg: 'join-in-banner1.jpg',
  title: '加盟政策',
  info: '开一个面馆 , 养一个小家',
}
const page1Swipt = [
  {img:'join-in-img1-1.jpg',title:'逐渐壮大企业规模',info:'随着品牌近5年的发力，越来越多小餐饮创业者选择常青麦香园'},
  {img:'join-in-img1-2.jpg',title:'商学院手把手培训',info:'常青麦香园商学院，统一的技能培训和管理培训'},
  {img:'join-in-img1-3.jpg',title:'航母级的中央厨房',info:'智慧鲜食工厂正式投产，四条热干面生产线，日常面条10万斤'},
  {img:'join-in-img1-4.jpg',title:'推陈出新产品研发',info:'横向研发配搭小食及定期的促销单品，乃至更健康、营养的早餐组合'},
  {img:'join-in-img1-5.jpg',title:'成熟高效门店管理',info:'手把手教您如何开店，一对一解答营运中出现的问题。'},
  {img:'join-in-img1-6.jpg',title:'迎合客户市场推广',info:'公司每年在品牌推广及形象搭建上的投入不下百万'},
]
class HanTasteFood extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		}
  }
  componentWillUnmount() {
    if (this.swiper1) { // 销毁swiper
			this.swiper1.destroy();
    }
	}
	componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
		if(this.swiper1){
			this.swiper1.slideTo(0, 0);
			this.swiper1.destroy();
			this.swiper1 = null;
		}
		this.swiper1 = new Swiper('#page1-container', {
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.page1-next',
        prevEl: '.page1-prev',
      },
		});
	}
	// componentDidUpdate(){
	// }
	render() {
	  return (
      <div className='join-in-policy' id='join-in-policy'>
        <HeadModle value='4' keyValue='setting:4_1'></HeadModle>
        <div id="body-frame">
          <div className="join-in-swiper swiper-container" id="join-in-swiper" style={{height: this.state.swiperHeight}}>
            <div className="main-swiper-wrapper">
              <div className="swiper-slide main-swiper-slide page0">
                <BannerModule value={topBannerObj}></BannerModule>
                <div className="button-div">
                  <a href="online_registration">我要加盟</a>
                </div>
              </div>
              <div className="swiper-slide main-swiper-slide page6">
                <div className="slide-frame">
                  <div className="frame-div">
                    <div className="title-frame">
                      <div className="title">如何加盟常青麦香园</div>
                    </div>
                    <div className="list-div">
                      <ul className="clear">
                        <li className="fl-l">
                          <div className="li-main">
                            <div className="num"><span>01</span></div>
                            <div className="title">录入信息/电话咨询</div>
                            <div className="info">录入个人合法的基本信息<br/>意向咨询：<b>400-778-558</b></div>
                          </div>
                        </li>
                        <li className="fl-l">
                          <div className="li-main">
                            <div className="num"><span>02</span></div>
                            <div className="title">提交审核</div>
                            <div className="info">公司审核筛选、面审<br/>合格后签订加盟意向协议</div>
                          </div>
                        </li>
                        <li className="fl-l">
                          <div className="li-main">
                            <div className="num"><span>03</span></div>
                            <div className="title">通过审核</div>
                            <div className="info">正式签订《常青麦香园品牌及产品特许经营合同》<br/>门店装修及全职培训（签订合同本人必须全程参加）</div>
                          </div>
                        </li>
                        <li className="fl-l">
                          <div className="li-main">
                            <div className="num"><span>04</span></div>
                            <div className="title">开业营业</div>
                            <div className="info">开业前筹备<br/>开业指导<br/>后期经营督导</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide main-swiper-slide page1">
                <div className="slide-frame">
                  <div className="frame-div">
                    <div className="title-frame">
                      <div className="title">加盟优势</div>
                    </div>
                    <div className="page1-main">
                      <div className="swiper-container page1-container" id='page1-container'>
                        <div className="swiper-wrapper">
                          {page1Swipt.map((list,index)=>{
                            return (
                              <div className="swiper-slide page1-slide" key={index}>
                                <div className="slide-content">
                                  <div className="image-div">
                                    <img src={require('../images/' + list.img)} alt=""/>
                                  </div>
                                  <div className="title-div">{list.title}</div>
                                  <div className="info-div" dangerouslySetInnerHTML={{__html: list.info}}></div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="swiper-button-next page1-next"></div>
                      <div className="swiper-button-prev page1-prev"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide main-swiper-slide page2">
                <div className="slide-frame">
                  <div className="frame-div">
                    <div className="title-frame">
                      <div className="title">系统支持</div>
                    </div>
                    <div className="list-div">
                      <ul className="clear">
                        <li className="fl-l">
                          <div className="li-main">
                            <div className="image">
                              <img src={require('../images/join-in-ico2-1.png')} alt=""/>
                            </div>
                            <div className="title">运营</div>
                            <div className="info">协助选址与评估<br/>运营指导和协助<br/>提高面馆市场竞争力</div>
                          </div>
                        </li>
                        <li className="fl-l">
                          <div className="li-main">
                            <div className="image">
                              <img src={require('../images/join-in-ico2-2.png')} alt=""/>
                            </div>
                            <div className="title">形象</div>
                            <div className="info">统一品牌形象和装修<br/>统一后厨设备<br/>统一品牌推广</div>
                          </div>
                        </li>
                        <li className="fl-l">
                          <div className="li-main">
                            <div className="image">
                              <img src={require('../images/join-in-ico2-3.png')} alt=""/>
                            </div>
                            <div className="title">培训</div>
                            <div className="info">0基础产品培训<br/>门店经营管理培训<br/>客户服务培训<br/>商学院全方位培训支持 </div>
                          </div>
                        </li>
                        <li className="fl-l">
                          <div className="li-main">
                            <div className="image">
                              <img src={require('../images/join-in-ico2-4.png')} alt=""/>
                            </div>
                            <div className="title">供应链</div>
                            <div className="info">智慧鲜食工厂<br/>食材统一配送<br/>冷链物流配送极速到店</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide main-swiper-slide page3">
                <div className="slide-frame">
                  <div className="frame-div">
                    <div className="title-frame">
                      <div className="title">理想加盟者</div>
                    </div>
                    <div className="top-frame clear">
                      <div className="image fl-l">
                        <img src={require('../images/join-in-img3-1.jpg')} alt=""/>
                      </div>
                      <div className="words fl-r">
                        <ul>
                          <li>吃苦耐劳，热爱餐饮</li>
                          <li>具有一定餐饮行业服务意识及经营管理</li>
                          <li>有一定固定资金及流动资金</li>
                          <li>加盟者在特许经营期间专职经营日常管理</li>
                          <li>无一夜暴富坐享其成幻想</li>
                          <li>遵守常青麦香园经营操作标准及指示说明</li>
                          <li>对常青麦香园的品牌文化，价值观、经营理念一致</li>
                        </ul>
                      </div>
                    </div>
                    <div className="bottom-list">
                      <ul className="clear">
                        <li className="fl-l">
                          <a href="/partner_story#1">
                            <div className="li-main">
                              <div className="image-div">
                                <img src={require('../images/join-in-img3-2.png')} alt=""/>
                              </div>
                              <div className="ico-div">
                                <img src={require('../images/join-in-icon3-1.png')} alt=""/>
                              </div>
                              <div className="words"><span className="name">黄培</span><span className="line">|</span>徐东才茂店</div>
                            </div>
                          </a>
                        </li>
                        <li className="fl-l">
                          <a href="/partner_story#2">
                            <div className="li-main">
                              <div className="image-div">
                                <img src={require('../images/join-in-img3-3.png')} alt=""/>
                              </div>
                              <div className="ico-div">
                                <img src={require('../images/join-in-icon3-1.png')} alt=""/>
                              </div>
                              <div className="words"><span className="name">李红波</span><span className="line">|</span>河南加盟店</div>
                            </div>
                          </a>
                        </li>
                        <li className="fl-l">
                          <a href="/partner_story#3">
                            <div className="li-main">
                              <div className="image-div">
                                <img src={require('../images/join-in-img3-4.png')} alt=""/>
                              </div>
                              <div className="ico-div">
                                <img src={require('../images/join-in-icon3-1.png')} alt=""/>
                              </div>
                              <div className="words"><span className="name">程罡</span><span className="line">|</span>黄石加盟店</div>
                            </div>
                          </a>
                        </li>
                        <li className="fl-l">
                          <a href="/partner_story#4">
                            <div className="li-main">
                              <div className="image-div">
                                <img src={require('../images/join-in-img3-5.png')} alt=""/>
                              </div>
                              <div className="ico-div">
                                <img src={require('../images/join-in-icon3-1.png')} alt=""/>
                              </div>
                              <div className="words"><span className="name">吴皓</span><span className="line">|</span>磨山港湾店</div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide main-swiper-slide page4">
                <div className="slide-frame">
                  <div className="frame-div">
                    <div className="image-frame">
                      <img src={require('../images/join-in-img4-1.png')} alt=""/>
                    </div>
                    <div className="btn-div">
                      <a href="online_registration">我要加盟</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide main-swiper-slide page10">
                <FooterModule></FooterModule>
              </div>
            </div>
          </div>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}

export default HanTasteFood
