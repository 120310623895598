import React from 'react'
import '../styles/HeadModle.less'

class HeadModle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 1,
      controlState: false,
    };
  }
  handleClick = (i) => {
    this.setState({
      current: i,
    });
  }
  controlClick(){
    const bodyFrame = document.getElementById("body-frame");
    const bodyClass = this.state.controlState ? '' : 'blur';
    bodyFrame.setAttribute("class",bodyClass);
    this.setState({controlState:!this.state.controlState})
  }
  render() {
    const propsValue = parseInt(this.props.value);
    const propsKey = this.props.keyValue;
    return (
      <div className="head-frame" id="head-frame">
        <div className="head-main">
          <div className="logo-div">
            <a href="/home">
              <img src={require('../images/head-logo.png')} alt=""/>
            </a>
          </div>
          <div className="nav-frame" id="nav-frame">
            <div className="nav-wrapper">
              <div className="first-nav">
                <ul className="clear">
                  <li className={propsValue === 1 ? "current" : ""}>
                    <div className="li-div">了解我们</div>
                  </li>
                  <li className={propsValue === 2 ? "current" : ""}>
                    <div className="li-div">早点精彩</div>
                  </li>
                  <li className={propsValue === 3 ? "current" : ""}>
                    <div className="li-div">热干面学院</div>
                  </li>
                  <li className={propsValue === 4 ? "current" : ""}>
                    <div className="li-div">我要加盟</div>
                  </li>
                  <li className={propsValue === 5 ? "current" : ""}>
                    <div className="li-div">联系互动</div>
                  </li>
                </ul>
              </div>
              <div className="second-nav">
                <div className="second-frame clear">
                  <div className="nav-div">
                    <ul>
                      <li className={propsKey === "setting:1_1" ? "current" : ""} key="setting:1_1"><a href="/company_profile" rel="noopener noreferrer">企业简介</a></li>
                      {/* <li className={propsKey === "setting:1_2" ? "current" : ""} key="setting:1_2"><a href="/business_philosophy"  rel="noopener noreferrer">经营理念</a></li> */}
                      <li className={propsKey === "setting:1_3" ? "current" : ""} key="setting:1_3"><a href="/hot_noodles"  rel="noopener noreferrer">品牌故事</a></li>
                      {/* <li className={propsKey === "setting:1_4" ? "current" : ""} key="setting:1_4"><a href="/fresh_factory"  rel="noopener noreferrer">智慧鲜食工厂</a></li> */}
                      <li className={propsKey === "setting:1_5" ? "current" : ""} key="setting:1_5"><a href="/news_activity"  rel="noopener noreferrer">新闻活动</a></li>
                    </ul>
                  </div>
                  <div className="nav-div">
                    <ul>
                      {/* <li className={propsKey === "setting:2_1" ? "current" : ""} key="setting:2_1"><a href="/hot_noodles"  rel="noopener noreferrer">传承·石墨醤热干面</a></li> */}
                      <li className={propsKey === "setting:2_2" ? "current" : ""} key="setting:2_2"><a href="/features_surface"  rel="noopener noreferrer">汉味·美食</a></li>
                      {/* <li className={propsKey === "setting:2_3" ? "current" : ""} key="setting:2_3"><a href="/features_surface"  rel="noopener noreferrer">特色粉面</a></li> */}
                      {/* <li className={propsKey === "setting:2_4" ? "current" : ""} key="setting:2_4"><a href="/han_taste_snacks"  rel="noopener noreferrer">汉味小吃</a></li> */}
                    </ul>
                  </div>
                  <div className="nav-div">
                    <ul>
                      <li className={propsKey === "setting:3_1" ? "current" : ""} key="setting:3_1"><a href="/school_profile"  rel="noopener noreferrer">学院介绍</a></li>
                      <li className={propsKey === "setting:3_2" ? "current" : ""} key="setting:3_2"><a href="/professional_teaching"  rel="noopener noreferrer">专业教学</a></li>
                      <li className={propsKey === "setting:3_3" ? "current" : ""} key="setting:3_3"><a href="/cooking_salon"  rel="noopener noreferrer">烹饪沙龙</a></li>
                      <li className={propsKey === "setting:3_4" ? "current" : ""} key="setting:3_4"><a href="/entrepreneurial_platform"  rel="noopener noreferrer">创业平台</a></li>
                    </ul>
                  </div>
                  <div className="nav-div">
                    <ul>
                      <li className={propsKey === "setting:4_1" ? "current" : ""} key="setting:4_1"><a href="/join_in_policy"  rel="noopener noreferrer">加盟政策</a></li>
                      <li className={propsKey === "setting:4_2" ? "current" : ""} key="setting:4_2"><a href="/partner_story"  rel="noopener noreferrer">合伙人故事</a></li>
                      <li className={propsKey === "setting:4_3" ? "current" : ""} key="setting:4_3"><a href="/store_display"  rel="noopener noreferrer">店铺展示</a></li>
                      <li className={propsKey === "setting:4_4" ? "current" : ""} key="setting:4_4"><a href="/online_registration"  rel="noopener noreferrer">在线报名</a></li>
                    </ul>
                  </div>
                  <div className="nav-div">
                    <ul>
                      {/* <li className={propsKey === "setting:5_1" ? "current" : ""} key="setting:5_1"><a href="/store_display"  rel="noopener noreferrer">门店版图</a></li> */}
                      <li className={propsKey === "setting:5_2" ? "current" : ""} key="setting:5_2"><a href="/recruitment"  rel="noopener noreferrer">招兵买马</a></li>
                      <li className={propsKey === "setting:5_3" ? "current" : ""} key="setting:5_3"><a href="/cooperation"  rel="noopener noreferrer">合作联系</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={this.state.controlState ? "phone-nav up" : "phone-nav"}>
          <div className="logo-div fl-l">
            <a href="/home">
              <img src={require('../images/head-logo.png')} alt=""/>
            </a>
          </div>
          <div className="control-div">
            <button className={this.state.controlState ? "down" : ""} type="button" onClick={()=>this.controlClick()}></button>
          </div>
          <div className="nav-main">
            <ul>
              <li className={this.state.current === 1 ? "first-li current" : "first-li"}>
                <div className="first-nav" onClick={()=>this.handleClick(1)}>了解我们</div>
                <div className="second-nav">
                  <ul>
                    <li className="second-li"><a href="/company_profile" rel="noopener noreferrer">企业简介</a></li>
                    {/* <li className="second-li"><a href="/business_philosophy"  rel="noopener noreferrer">经营理念</a></li> */}
                    <li className="second-li"><a href="/hot_noodles"  rel="noopener noreferrer">品牌故事</a></li>
                    {/* <li className="second-li"><a href="/fresh_factory"  rel="noopener noreferrer">智慧鲜食工厂</a></li> */}
                    <li className="second-li"><a href="/news_activity"  rel="noopener noreferrer">新闻活动</a></li>
                  </ul>
                </div>
              </li>
              <li className={this.state.current === 2 ? "first-li current" : "first-li"}>
                <div className="first-nav" onClick={()=>this.handleClick(2)}>早点精彩</div>
                <div className="second-nav">
                  <ul>
                    {/* <li className="second-li"><a href="/hot_noodles"  rel="noopener noreferrer">传承·石墨醤热干面</a></li> */}
                    <li className="second-li"><a href="/features_surface"  rel="noopener noreferrer">汉味·美食</a></li>
                    {/* <li className="second-li"><a href="/features_surface"  rel="noopener noreferrer">特色粉面</a></li>
                    <li className="second-li"><a href="/han_taste_snacks"  rel="noopener noreferrer">汉味小吃</a></li> */}
                  </ul>
                </div>
              </li>
              <li className={this.state.current === 3 ? "first-li current" : "first-li"}>
                <div className="first-nav" onClick={()=>this.handleClick(3)}>热干面学院</div>
                <div className="second-nav">
                  <ul>
                    <li className="second-li"><a href="/school_profile"  rel="noopener noreferrer">学院介绍</a></li>
                    <li className="second-li"><a href="/professional_teaching"  rel="noopener noreferrer">专业教学</a></li>
                    <li className="second-li"><a href="/cooking_salon"  rel="noopener noreferrer">烹饪沙龙</a></li>
                    <li className="second-li"><a href="/entrepreneurial_platform"  rel="noopener noreferrer">创业平台</a></li>
                  </ul>
                </div>
              </li>
              <li className={this.state.current === 4 ? "first-li current" : "first-li"}>
                <div className="first-nav" onClick={()=>this.handleClick(4)}>我要加盟</div>
                <div className="second-nav">
                  <ul>
                    <li className="second-li"><a href="/join_in_policy"  rel="noopener noreferrer">加盟政策</a></li>
                    <li className="second-li"><a href="/partner_story"  rel="noopener noreferrer">合伙人故事</a></li>
                    <li className="second-li"><a href="/store_display"  rel="noopener noreferrer">店铺展示</a></li>
                    <li className="second-li"><a href="/online_registration"  rel="noopener noreferrer">在线报名</a></li>
                  </ul>
                </div>
              </li>
              <li className={this.state.current === 5 ? "first-li current" : "first-li"}>
                <div className="first-nav" onClick={()=>this.handleClick(5)}>联系互动</div>
                <div className="second-nav">
                  <ul>
                    {/* <li className="second-li"><a href="/store_display"  rel="noopener noreferrer">门店版图</a></li> */}
                    <li className="second-li"><a href="/recruitment"  rel="noopener noreferrer">招兵买马</a></li>
                    <li className="second-li"><a href="/relationship_between"  rel="noopener noreferrer">合作联系</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default HeadModle;
