import React from 'react';
import '../styles/RightSuspension.less';
      
class RightSuspension extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navState: false,
    };
  }
  comeTop(){
    document.querySelector('html').scrollTop = 0;
  }
  handleClick() {
    window.easemobim.config = {
        hide: true,
        autoConnect: true    
      };
    window.easemobim.bind({
      configId: "879570dc-d912-4ebc-90ba-e52ad16c3f79"
    });
  }
  handleNavClick(){
    const navFrame = document.getElementById("nav-frame");
    const navClass = this.state.navState ? 'nav-frame' : 'nav-frame show';
    navFrame.setAttribute("class",navClass);
    const bodyFrame = document.getElementById("body-frame");
    const bodyClass = this.state.navState ? '' : 'blur';
    bodyFrame.setAttribute("class",bodyClass);
    this.setState({
      navState: !this.state.navState,
    })
  }
	componentDidMount(){
	}
  render() {
    return (
      <div className="right-suspension" id="right-suspension">
        <ul>
          <li>
            <button className="li-div" onClick={() => this.handleNavClick()}>
              <div className={this.state.navState ? 'nav-ico close' : 'nav-ico'}>
                <span className="n1"></span>
                <span className="n2"></span>
                <span className="n3"></span>
              </div>
              <div className="words-div">导航</div>
            </button>
          </li>
          <li className="li2">
            <button className="li-div" onClick={() => this.handleClick()}>
              <div className="image-div">
                <img src={require('../images/right-ico1.svg')} alt=""/>
              </div>
              <div className="words-div">客服</div>
            </button>
          </li>
          <li className="li3">
            <div className="li-div">
              <div className="image-div">
                <img src={require('../images/right-ico2.svg')} alt=""/>
              </div>
              <div className="words-div">微信</div>
            </div>
            <div className="wechat-div">
              <div className="image-div">
                <img src={require('../images/index-qrcode.png')} alt=""/>
              </div>
              <div className="words-div">官方微信</div>
            </div>
          </li>
          <li className='come-top'>
            <div className="li-div" onClick={()=>this.comeTop()}>
              <div className="image-div">
                <img src={require('../images/right-ico3.svg')} alt="" />
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default RightSuspension;
