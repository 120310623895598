import React from 'react'
import { Row, Col } from 'antd';
import $$ from 'cmn-utils';
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import SwiperFreshFactory from '../components/SwiperFreshFactory';
import '../styles/entrepreneurial_platform.less';

const topBannerObj = {
  bgImg: 'entrepreneurial-platform-banner1.png',
  title: '创业平台',
  info: '中国第一所武汉热干面培训学校',
}
const twoImg = [
  {img:'entrepreneurial-platform-ad2.png',link:'/online_registration',title:'品牌孵化',info:'技术单品自主创业'},
  {img:'entrepreneurial-platform-ad3.png',link:'/online_registration',title:'招商加盟 '},
]
class EntrepreneurialPlatform extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      bannerArr: [],
		}
	}
  componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
    $$.post('/api/home/banner/search',{type:4},{
      credentials:'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }, 
    })
    .then(data => {
      this.setState({
        bannerArr: data.data.list,
      });
    })
  }
  render() {
    return(
      <div className='entrepreneurial-platform' id='entrepreneurial-platform'>
        <HeadModle value='3' keyValue='setting:3_4'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="top-ad">
            <div className="portrait-div">
              <img src={require('../images/business-philosophy-img1.png')} alt=""/>
            </div>
            <div className="image-div">
              <img src={require('../images/entrepreneurial-platform-ad1.png')} alt=""/>
            </div>
            <div className="words">
              <div className="title">常青麦香园多种合作方式</div>
              <div className="info">
                <p>人才输出、加盟、联营、事业合伙人等多种方式</p>
                <p>帮助创业者开一个面店，养活一家人，成就一份事业的梦想</p>
              </div>
            </div>
          </div>
          <div className="two-image">
            <Row>
              {
                twoImg.map((list,index)=>{
                  return (
                    <Col span={12} key={index} className='li-div'>
                      <a href={list.link}>
                        <div className="image-div">
                          <img src={require('../images/' + list.img)} alt=""/>
                        </div>
                        <div className="cover-frame">
                          <div className="title-div">{list.title}</div>
                          <div className="info-div">{list.info}</div>
                          <div className="more-div">我要加盟<img src={require('../images/footer-arrow-ico2.svg')} alt=""/></div>
                        </div>
                      </a>
                    </Col>
                  )
                })
              }
            </Row>
          </div>
          <div className="banner-div">
            <SwiperFreshFactory value={this.state.bannerArr}></SwiperFreshFactory>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}

export default EntrepreneurialPlatform
