import React from 'react'
import $$ from 'cmn-utils';
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import '../styles/news_details.less';

class NewsDetails extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      newsContent:{},
		}
	}
	componentDidMount(){
    const id = window.location.search.substr(1);
    console.log(id);
    $$.get("api/home/news/view?id="+id,{},{
      credentials:'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }, 
    })
    .then(data => {
      console.log(data);
      this.setState({
        newsContent: data.data,
      });
    })
	}
	render() {
	  return (
      <div className='news-details' id='news-details'>
        <HeadModle value='1' keyValue='setting:1_5'></HeadModle>
        <div id="body-frame">
          <div className="detail-banner">
            <div className="bg-div"></div>
            <div className="words-div">
              <div className="title-div">{this.state.newsContent.title}</div>
              <div className="info-div">{this.state.newsContent.intro}</div>
            </div>
          </div>
          <div className="article-frame">
            <div className="frame-div">
              <div className="article-content" dangerouslySetInnerHTML={{__html: this.state.newsContent.content}}></div>
              {/* <div className="page-frame clear">
                <div className="prev-div fl-l">
                  <button className="prev-btn"><span>{'<<'}</span>上一篇</button>
                </div>
                <div className="next-div fl-r">
                  <button className="prev-btn">下一篇<span>>></span></button>
                </div>
              </div> */}
            </div>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}

export default NewsDetails
