import React from 'react'
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';

class About extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		}
	}
	componentDidMount(){
	}
	render() {
	  return (
      <div className='school-profile' id='school-profile'>
        <HeadModle value='setting:3_1'></HeadModle>
        <div>
          <h1>公司介绍</h1> 
          <a href="http://www.baidu.com">baidu3</a>
          <a href="http://www.baidu.com">baidu3</a>
          
          <a href="http://www.baidu.com">baidu3</a>
        </div>
        <FooterModule></FooterModule>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}
export default About
