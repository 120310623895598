import React from 'react'
import { Map, Marker } from 'react-amap';
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import '../styles/relationship_between.less';

const topBannerObj = {
  bgImg: 'relationship-between-banner1.png',
  title: '合作联系',
  info: '常青麦香园愿与你携手共创大美格局',
}
const infoList = [
  {class:'phone',title:'品牌合作',info:'公司以可持续的品牌战略规划促进企业经营良性发展<br/>通过优质的品牌运营塑造企业的核心专长，打造品牌核心竞争力<br/>品牌营销中心：4008778558<br/>品牌异业合作：cjj@changqingmxy.com'},
  {class:'mail',title:'加盟合作',info:'公司拥有前沿理念、雄厚实力、多年运营经验，形成行业领先的品牌连锁运营能力<br/>完善独到的品牌定位、优势的运营平台为创业者提供品牌与技术支持服务加盟<br/>加盟热线：4008-778-558<br/>'},
  {class:'fax',title:'多元合作',info:'配合企业整体品牌战略的推进，期冀展开多样化的传媒合作<br/>实现品牌管理的最大能效，把握品牌战略的有效施行，明确品牌发展的大方向<br/>媒体合作：4008-778-558 '},
  {class:'address',title:'企业联系',info:'湖北常青麦香园餐饮管理有限公司<br/>公司地址：湖北省武汉市东西湖<br/>邮编：4300000<br/>客户专线：4008778558 '},
]

class RelationshipBetween extends React.Component {
	constructor(props){
		super(props);
		this.state = {
    }
    this.mapPlugins = ['ToolBar'];
    this.mapCenter = {longitude: 120, latitude: 35};
    this.markerPosition = {longitude: 114.241886, latitude: 30.645377};
	}
  componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
  }
  render() {
    return(
      <div className='relationship-between' id='relationship-between'>
        <HeadModle value='5' keyValue='setting:5_3'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="main-frame">
            <div className="frame-div">
              <div className="top-list">
                <ul className="clear">
                  {infoList.map((list,index)=>{
                    return(
                      <li key={index} className={list.class + ' fl-l'}>
                        <div className="li-div">
                          <div className="title-div">{list.title}</div>
                          <div className="info-div" dangerouslySetInnerHTML={{__html:list.info}}></div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="address-frame" style={{display:'none'}}>
                  <div className="map-frame">
                    <Map
                      plugins={this.mapPlugins}
                      center={this.markerPosition}
                      zoom={15}>
                      <Marker position={this.markerPosition} />
                    </Map>
                  </div>
              </div>
            </div>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}

export default RelationshipBetween
