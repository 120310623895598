import React from 'react'
import $$ from 'cmn-utils';
import { Row, Col, Spin, Icon } from 'antd';
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import '../styles/features_surface.less';

// const foodType = [
//   {img: 'features-surface-ico1.png',title:'石墨酱热干面',value:"31"},
//   {img: 'features-surface-ico2.png',title:'特色粉面',value:"30"},
//   {img: 'features-surface-ico3.png',title:'炒饭炒面',value:"9"},
//   {img: 'features-surface-ico4.png',title:'香汁米饭 ',value:"8"},
//   {img: 'features-surface-ico5.png',title:'妈妈砂锅饭',value:"32"},
//   {img: 'features-surface-ico6.png',title:'汉味小吃',value:"33"},
//   {img: 'features-surface-ico7.png',title:'小碗菜',value:"34"},
//   {img: 'features-surface-ico8.png',title:'粥品',value:"35"},
//   {img: 'features-surface-ico9.png',title:'汤品',value:"36"},
//   {img: 'features-surface-ico10.png',title:'饮品',value:"37"},
// ]
const topBannerObj = {
  bgImg: 'han-taste-banner1.png',
  title: '汉味精彩美食',
  info: '尝遍千般滋味，道道都是美味',
}
class FeaturesSurface extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      foodList: [],
      foodTypeCurrent: '0',
      foodType: [],
      spinState: false,
		}
  }
  typeClick(value){
    this.dataFun(value);
    this.setState({
      foodList: [],
      foodTypeCurrent: value,
    });
  }
  dataFun(cat_id){
    this.setState({
      spinState: true,
    });
    $$.post('/api/home/product/search',{cat_id:cat_id},{
      credentials:'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }, 
    })
    .then(data => {
      this.setState({
        foodList: data.data.list,
        spinState: false,
      });
    })
  }
  getCate(){
    $$.post('/api/home/cat/dropdown',{},{
      credentials:'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }, 
    })
    .then(data => {
      console.log('cate:',data);
      if(data.ret === 200){
        this.dataFun(data.data[3].children[0].id);
        this.setState({
          foodType: data.data[3].children,
          foodTypeCurrent: data.data[3].children[0].id,
        });
      }
    })
  }
  componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
    this.getCate();
  }
  render() {
    return(
      <div className='features-surface' id='features-surface'>
        <HeadModle value='2' keyValue='setting:2_2'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="surface-type">
            <div className="type-list">
              <ul className="clear">
                {!!this.state.foodType && this.state.foodType.map((list,index)=>{
                  return (
                    <li key={list.id} className={this.state.foodTypeCurrent === list.id ? 'current' : ''}>
                      <div className="li-div" onClick={()=>this.typeClick(list.id)}>
                        <div className="image-div">
                          <img src={list.icon} alt=""/>
                        </div>
                        <div className="words-div">{list.cat_name}</div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className={this.state.foodTypeCurrent === '31' ? "food-type hot" : "food-type"}>
            <div className="frame-div">
              <Row>
                {this.state.foodList.map((list,index)=>{
                  return (
                    <Col span={8} key={index} className="col-frame">
                      <div className="col-div">
                        <div className="image-div">
                          <img src={list.icon} alt=""/>
                        </div>
                        <div className="words-div">{list.product_name}</div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
              <div className="spin-div">
                <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} tip="Loading..." spinning={this.state.spinState}  />
              </div>
              <div className="statement-div">* 常青麦香园在各店面产品不同，以门店实售情况为准</div>
            </div>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}
export default FeaturesSurface
