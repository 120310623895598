import React from 'react';
import Swiper from 'swiper/dist/js/swiper.js'
import { Row, Col, Popover } from 'antd';
import 'swiper/dist/css/swiper.min.css'
import '../styles/FooterModule.less';

const footerNav = [
  {img:'footer-ico1.svg',name:'我要加盟',link:'/join_in_policy'},
  {img:'footer-ico2.svg',name:'招兵买马',link:'/recruitment'},
  {img:'footer-ico3.svg',name:'门店信息',link:'/store_display#banner-div'},
  {img:'footer-ico4.svg',name:'我是会员',link:'/relationship_between'},
]
const contactWay = [
  {img:'footer-ico5.svg',name:'微信',bigImg:'index-qrcode.png'},
  //{img:'footer-ico6.svg',name:'微博',bigImg:'index-qrcode2.png'},
]
class FooterModle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentWillUnmount() {
		if (this.swiper) { // 销毁swiper
			this.swiper.destroy();
		}
	}
	componentDidMount(){
		if(this.swiper){
			this.swiper.slideTo(0, 0);
			this.swiper.destroy();
			this.swiper = null;
		}
		this.swiper = new Swiper('.qrcode-swiper', {
			loop:true,
			pagination: {
				el: '.qrcode-pagination',
				// type: 'fraction',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">0' + (index + 1) + '</span>';
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
		});
	}
  render() {
    return (
      <div className="footer-frame" id="footer-frame">
        <div className="top-frame">
          <div className="frame-main">
            <div className="footer-nav">
              <Row>
                {
                  footerNav.map((list,index)=>{
                    return (
                      <Col span={6} key={index}>
                        <div className="li-div">
                          <a href={list.link}>
                            <div className="image-div">
                              <img src={require('../images/' + list.img)} alt=""/>
                            </div>
                            <div className="name-div">{list.name}</div>
                          </a>
                        </div>
                      </Col>
                    )
                  })
                }
              </Row>
            </div>
            <div className="qrcode-pc">
              <Row>
                {
                  contactWay.map((list,index)=>{
                    return (
                      <Col span={12} key={index}>
                        <Popover content={<img className="footer-img" src={require('../images/' + list.bigImg)} alt=""/>}>
                          <div className="li-div">
                            <div className="image-div">
                              <img src={require('../images/' + list.img)} alt=""/>
                            </div>
                            <div className="name-div">{list.name}</div>
                          </div>
                        </Popover>
                      </Col>
                    )
                  })
                }
              </Row>
            </div>
            <div className="qrcode-wap clear">
              <div className="qrcode-li fl-l">
                <div className="qrcode-div">
                  <div className="image-div">
                    <img src={require('../images/index-qrcode.png')} alt=""/>
                  </div>
                  <div className="words-div">官方微信</div>
                </div>
              </div>
              <div className="qrcode-li fl-l" style={{display:"none"}}>
                <div className="qrcode-div">
                  <div className="image-div">
                    <img src={require('../images/index-qrcode2.png')} alt=""/>
                  </div>
                  <div className="words-div">官方微博</div>
                </div>
              </div>
            </div>
            <div className="footer-list">
              <div className="li-div">
                <div className="first-nav">
                  <p>了解我们</p>
                </div>
                <div className="second-nav">
                  <p><a href="/company_profile">企业简介</a></p>
                  <p><a href="/business_philosophy">经营理念</a></p>
                </div>
              </div>
              <div className="li-div">
                <div className="first-nav">
                  <p>热干面商学院</p>
                </div>
                <div className="second-nav">
                  <p><a href="/school_profile">学院介绍</a></p>
                  <p><a href="/professional_teaching">专业教学</a></p>
                </div>
              </div>
              <div className="li-div">
                <div className="first-nav">
                  <p>我要加盟</p>
                </div>
                <div className="second-nav">
                  <p><a href="/join_in_policy">加盟政策</a></p>
                  <p><a href="/partner_story">合伙人故事</a></p>
                </div>
              </div>
              <div className="li-div">
                <div className="first-nav">
                  <p>联系互动</p>
                </div>
                <div className="second-nav">
                  <p><a href="/recruitment">招兵买马</a></p>
                  <p><a href="/relationship_between">合作联系</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-frame">
          <div className="frame-main clear">
            <div className="right-info fl-r"><span className="phone-span">服务电话</span><a href="tel:4008778558">4008-778-558</a></div>
            <div className="left-info fl-l">
              <p>地址：武汉市东西湖走马岭街食品一路32号常青麦香园</p>
              <p>版权：湖北常青麦香园餐饮管理有限公司<span>CopyRight ® (2019) CQMXY  All Rights Reserved. <a href="http://beian.miit.gov.cn/">鄂ICP备19008383号-1</a></span></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterModle;
