import React from 'react'
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'
import HeadModle from '../components/HeadModle'
import FooterModule from '../components/FooterModule'
import RightSuspension from '../components/RightSuspension'
import BannerModule from '../components/BannerModule'
import '../styles/hot_noodles.less'

const topBannerObj = {
  bgImg: 'brand-story-banner1.jpg',
  title: '常青麦香园故事',
  info: '常青麦香园，热干面的三代传承',
}
const meaningArr1 = ["brand-story-meaning1.png","brand-story-meaning2.png","brand-story-meaning3.png","brand-story-meaning4.png","brand-story-meaning5.png","brand-story-meaning6.png","brand-story-meaning7.png","brand-story-meaning8.png","brand-story-meaning9.png","brand-story-meaning10.png","brand-story-meaning11.png","brand-story-meaning12.png",]
const meaningArr2 = ["brand-story-wap1.jpg","brand-story-wap2.jpg","brand-story-wap3.jpg","brand-story-wap4.jpg","brand-story-wap5.jpg","brand-story-wap6.jpg","brand-story-wap7.jpg","brand-story-wap8.jpg","brand-story-wap9.jpg","brand-story-wap10.jpg",]
class HotNoodles extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      meaningArr: [],
      direction: 'horizontal',
		}
	}
  componentWillUnmount() {
		if (this.swiper) { // 销毁swiper
			this.swiper.destroy();
		}
	}
	componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
    this.setState({
      meaningArr: (document.documentElement.clientWidth > 480) ? meaningArr1 : meaningArr2,
      direction: (document.documentElement.clientWidth > 480) ? 'horizontal' : 'vertical',
    })
  }
  meaningShow(){
    document.getElementById('meaning-frame').style.display = 'block';
    document.getElementById('body-frame').className = 'blur';
    if(this.swiper){
			this.swiper.slideTo(0, 0);
			this.swiper.destroy();
			this.swiper = null;
    }
    const $this = this;
		this.swiper = new Swiper('.meaning-swiper', {
      direction: $this.state.direction,
			loop:true,
			pagination: {
				el: '.meaning-pagination',
				clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"><i></i></span>';
        },
			},
			// autoplay: {
      //   delay: 2500,
      //   disableOnInteraction: false,
			// },
      navigation: {
        nextEl: '.meaning-next',
        prevEl: '.meaning-prev',
      },
      on:{
        slideChange: function(){
          let currentNo = 1;
          if(this.activeIndex % 12 === 0){
            currentNo = 12;
          }else{
            currentNo = this.activeIndex % 12;
          }
          $this.setState({currentNo});
        },
      },
		});
  }
  meaningHide(){
    document.getElementById('meaning-frame').style.display = 'none';
    document.getElementById('body-frame').className = ' ';
  }
  meaningHide2(){
    if(document.documentElement.clientWidth <= 480){
      document.getElementById('meaning-frame').style.display = 'none';
      document.getElementById('body-frame').className = ' ';
    }
  }
	render() {
	  return (
      <div className='hot-noodles' id='hot-noodles'>
        <HeadModle value='1' keyValue='setting:1_3'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="top-introduction">
            <div className="frame-div">热干面之都 • 早餐之都</div>
            <div className="info-div">用一碗面温暖一座城市</div>
          </div>
          <div className="top-news">
            <div className="words">以杂糅大江南北细腻而粗犷的生活方式著称的武汉人，每天的日子通常是从一碗热干面开始的。热干面与山西刀削面、两广伊府面、四川担担面、北方炸酱面并称为我国五大名面。作为地道的武汉人，三餐里，至少有一餐离不开它。</div>
            <div className="images clear">
              <div className="left-image fl-l">
                <div className="image-div">
                  <img src={require('../images/hot-noodles-ad1.png')} alt=""/>
                </div>
                <div className="meaning-div" onClick={()=>this.meaningShow()}>
                  了解热干面的奥义<img src={require('../images/hot-noodles-ico2.png')} alt=""/>
                </div>
              </div>
              <div className="right-image fl-r">
                <div className="image-div">
                  <img src={require('../images/hot-noodles-ad2.png')} alt=""/>
                </div>
              </div>
            </div>
          </div>
          <div className="early-origin">
            <div className="frame-div">“过早”是武汉人用早餐的俗称，这一词汇最早出现在清代道光年间有人写了这样一首《汉口竹枝词》：“三天过早异平常，—顿狼餐饭可忘，切面豆丝干线粉，鱼餐元子滚鸡汤”。更是反映了武汉“过早”的习俗和一些早点品种。有人说武汉人将“过早”提升到过年的位置，未免夸张了些，不过这个“过”字，用得确实讲究。</div>
          </div>
          <div className="old-photos">
            <div className="image">
              <img src={require('../images/hot-noodles-ad3.png')} alt=""/>
            </div>
          </div>
          <div className="main-bottom">
            <ul>
              <li>
                <div className="li-main clear">
                  <div className="image-div fl-l">
                    <img src={require('../images/hot-noodles-ad4.png')} alt=""/>
                  </div>
                  <div className="words-div fl-r">
                    <div className="first-title">妙手调制蔡林记</div>
                    <div className="second-title">蔡明纬（武汉热干面发明人，“蔡林记”创始人）</div>
                    <div className="info-div">
                      <p>上世纪30年代的一天清晨，长江边武汉关的终生敲响6下，睡梦中的人们也将开始一天的忙活了。</p>
                      <p>汉口中山大道满春路口的那家热干面馆早已经灯火展展，老板蔡明纬将已经煮得半熟的面备好，等待着巷弄里第一批早起的街坊。芝麻酱、小麻油、酱油、碎萝卜、葱花……客人来了，将半热的面条放上作料，一碗香喷喷的“麻酱面”就做好了。</p>
                      <p>抗争胜利后，蔡老板又开了一家面馆，并把弟弟接来帮忙，店名取门口两棵苦柬树的“集木为林，财源茂盛”之意，名曰“蔡林记”。从那时起，这碗面不仅是几代武汉人舌尖上的记忆，要传承着这座城市的历史和文化。</p>
                      <p>时光荏苒，白马过隙。90年代开始，大街小巷的小吃店都开始售卖热干面，可市民却惊呼面怎么不想香了。</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="li-main clear">
                  <div className="image-div fl-r">
                    <img src={require('../images/hot-noodles-ad5.png')} alt=""/>
                  </div>
                  <div className="words-div fl-l">
                    <div className="first-title">开门收徒传手艺</div>
                    <div className="second-title">蔡汉文（蔡明纬长子，武汉热干面嫡传人）</div>
                    <div className="info-div">2010年，蔡明纬百年诞辰，其长子蔡汉文决定将热干面家传配方公布于世，寻找继承人。蔡老游走很多家面馆，发现常青花园有一家面馆和蔡家老味道很相似，于是蔡老找到店老板攀谈起来。经得知店老板李亚娟在开面馆之前就有着20多年的中餐经验，对食材和味道的把握有着天生的敏感度及浓厚的兴趣。加上李亚娟高度认可蔡老坚持要用“100%纯芝麻酱、纯麻油”还原传统的做法。经过3年的观察期，最终在众学艺者中，蔡汉文选中了李亚娟为蔡家的嫡传弟子。此时，正宗蔡家热干面配方有了第三代传人。传说中的老汉味“热干面”再一次重现江湖！</div>
                  </div>
                </div>
              </li>
              <li>
                <div className="li-main clear">
                  <div className="image-div fl-l">
                    <img src={require('../images/hot-noodles-ad6.png')} alt=""/>
                  </div>
                  <div className="words-div fl-r">
                    <div className="first-title">用心打造街坊们的早餐连锁</div>
                    <div className="second-title">李亚娟（蔡汉文徒弟，热干面第三代嫡传）</div>
                    <div className="info-div">“立汉味为本，靠特色取胜”。在掌握了热干面嫡传配方的基础上，李亚娟潜心琢磨，在传统的制作工艺上创新，2011年自主发研制了全国首台“热干面一体生产设备”，用大规模数字化生产取代了传统手工作坊生产的转变。实现了整个热干面生产“工业化”、“无厨师化”、“蒸出来的热干面”的夙愿。用现代科技将传统热干面焕发新的生命力！</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          {/* <div className="story-frame">
            <div className="frame-div">
              <ul>
                <li>
                  <div className="li-div" onClick={()=>this.meaningShow()}>
                    <div className="image-div">
                      <img src={require('../images/brand-story-img3.png')} alt=""/>
                    </div>
                    <div className="words-div">
                      <div className="title-div">热干面的奥义</div>
                      <div className="info-div">匠心注入 热爱到底</div>
                      <div className="more-div">查看详情<img src={require('../images/footer-arrow-ico2.svg')} alt=""/></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div> */}
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
        <div className="meaning-frame" id="meaning-frame">
          <div className="frame-div">
            <div className="close-div" onClick={()=>this.meaningHide()}>
              <span className="close-span">×</span>
            </div>
            <div className="swiper-container meaning-swiper" onClick={()=>this.meaningHide2()}>
              <div className="swiper-wrapper">
                {!!this.state.meaningArr && this.state.meaningArr.map((list,index)=>{
                  return (
                    <div className={"swiper-slide slide" + index} key={index}>
                      <img src={require('../images/' + list)} alt=""/>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="swiper-pagination meaning-pagination"></div>
            <div className="swiper-button-next meaning-next"></div>
            <div className="swiper-button-prev meaning-prev"></div>
            <div className="number-div">{this.state.currentNo}/12</div>
          </div>
        </div>
      </div>
    )
  }
}

export default HotNoodles
