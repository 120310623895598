import React from 'react';
import { Row, Col } from 'antd';
import $$ from 'cmn-utils';
import HeadModle from '../components/HeadModle';
import SwiperModel from '../components/SwiperModel';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import { Player } from 'video-react';
import "../../node_modules/video-react/dist/video-react.css";
import '../styles/home.less';

const twoImg = [
  {img:'index-ad-img1.jpg',link:'/join_in_policy',title:'选择我们<b>没有错！</b>',info:'我们的使命是让每一位加盟我们的创业者都能创业成功'},
  {img:'index-ad-img2.jpg',link:'/school_profile',title:'中国第一所武汉热干面培训学校',info:'立足武汉，布局全国，向世界分享“过早文化”'},
]
const newsArr = [
  {images:'index-ad-img4.jpg',link_url:'/features_surface',title:'传承与创新 ：石磨芝麻酱热干面系列'},
  {images:'index-ad-img5.jpg',link_url:'/news_activity',title:'2019大阪G20首脑峰会美食宴│常青麦香园（G20特供）热干面得到世界认可！'},
]
let touchStart = 0;
let touchEnd = 0;
let touchEver = '';
class Home extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      bannerArr:[],
      newsArr:[]
		}
  }
  showVideo(){
    const homeFrame = document.querySelector(".home-frame");
    if(homeFrame.className.indexOf("full-page") < 0){
      homeFrame.className = homeFrame.className + " full-page";
      document.getElementById("body-frame").className = "fixed"
      setTimeout(()=>{
        if(document.documentElement.clientWidth > 480){
          this.refs.player.play();
        }
        homeFrame.querySelector(".front-mask").className = "front-mask big";
      },1000)
    }
  }
  hideVideo(){
    const homeFrame = document.querySelector(".home-frame");
    if(homeFrame.className.indexOf("full-page") > 0){
      homeFrame.querySelector(".front-mask").className = "front-mask";
      setTimeout(()=>{
        homeFrame.className = homeFrame.className.replace("full-page","");
        this.refs.player.pause();
      },400);
      setTimeout(()=>{
        document.getElementById("body-frame").className = "static";
      },1400);
    }
  }
  getScrollTop(){
    let scrollTop = 0;     
    if(document.documentElement && document.documentElement.scrollTop){     
     scrollTop = document.documentElement.scrollTop;     
    }else if(document.body){     
     scrollTop = document.body.scrollTop;     
    }
    return scrollTop;
  }
  windowAddMouseWheel(){
    const $this = this;
    const scrollFunc = function (e) {
      e = e || window.event;
      if (e.wheelDelta) {  //判断浏览器IE，谷歌滑轮事件
          if (e.wheelDelta > 0) { //当滑轮向上滚动时
            console.log("滑轮向上滚动");
            if($this.getScrollTop() == 0){
              $this.showVideo();
            }
          }
          if (e.wheelDelta < 0) { //当滑轮向下滚动时
            console.log("滑轮向下滚动");
            $this.hideVideo();
          }
      } else if (e.detail) {  //Firefox滑轮事件
          if (e.detail> 0) { //当滑轮向上滚动时
            console.log("滑轮向上滚动");
            if($this.getScrollTop() == 0){
              $this.showVideo();
            }
          }
          if (e.detail< 0) { //当滑轮向下滚动时
            console.log("滑轮向下滚动");
            $this.hideVideo();
          }
      }
    };
    //给页面绑定滑轮滚动事件
    if (document.addEventListener) {
        document.addEventListener('DOMMouseScroll', scrollFunc, false);
    }
    //滚动滑轮触发scrollFunc方法
    window.onmousewheel = document.onmousewheel = scrollFunc;
  }
  isParent (obj,parentObj){
    while (obj !== undefined && obj !== null && obj.tagName.toUpperCase() !== 'BODY'){
      if (obj === parentObj){
        return true;
      }
      obj = obj.parentNode;
    }
    return false;
  }
  handleTouchStart(e) {
    touchStart = e.touches[0].clientY;
    if (this.isParent(e.target, document.querySelector(".front-page"))) {
      touchEver = 'front';
    }else if (this.isParent(e.target, document.querySelector("#body-frame"))){
      touchEver = 'body';
    }
  }
  handleTouchMove(e) {
    // console.log(e.target);
    if(document.documentElement.clientWidth <= 480){
      if (touchEver === 'body') {
        if(this.getScrollTop() == 0){
          e.preventDefault();
          e.stopPropagation();
        }
      }
    }
  }
  handleTouchEnd(e) {
    const $this = this;
    touchEnd = e.changedTouches[0].clientY;
    if(document.documentElement.clientWidth <= 480){
      if (touchEver === 'front') {
        if (touchEnd + 50 < touchStart) {
          $this.hideVideo();
        }
      }else if (touchEver === 'body') {
        if (touchEnd - 50 > touchStart) {
          if($this.getScrollTop() == 0){
            $this.showVideo();
          }
        }
      }
    }
  }
	componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
    let type = 0;
    if(document.documentElement.clientWidth <= 480){
      type = 6;
      const homeFrame = document.querySelector(".home-frame");
      homeFrame.className = homeFrame.className + " full-page";
      document.getElementById("body-frame").className = "fixed"
      homeFrame.querySelector(".front-mask").className = "front-mask big";
      this.windowAddMouseWheel();
    }else{
      this.windowAddMouseWheel();
    }
    $$.post('/api/home/banner/search',{type,},{
      credentials:'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }, 
    })
    .then(data => {
      this.setState({
        bannerArr: data.data.list,
        newsArr: data.data.articles
      });
    })
	}
	render() {
	  return (
      <div 
        className='home-frame' 
        onTouchStart={(e) => this.handleTouchStart(e)}
        onTouchMove={(e) => this.handleTouchMove(e)}
        onTouchEnd={(e) => this.handleTouchEnd(e)}
      >
        <HeadModle></HeadModle>
        <div className="front-page">
          <div className="section-page">
            <div className="video-wrapper">
              <Player id="player" ref="player" videoId="video-1" autoPlay={false} muted={false} loop={true} controls={false} poster={"../images/index-banner.png"}>
                <source src={"https://maixiangyuan-image.oss-cn-beijing.aliyuncs.com/%E9%A6%96%E9%A1%B5.mp4"}/>
              </Player>
            </div>
          </div>
          <div className="video-logo">
            <img src={require('../images/index-video-logo.png')} alt=""/>
          </div>
          <div className="front-mask"></div>
          <button className="scroll-wheel" type="button" onClick={()=>this.hideVideo()}></button>
        </div>
        <div id="body-frame">
          <div className="banner-div">
            <SwiperModel value={this.state.bannerArr}></SwiperModel>
          </div>
          <div className="two-image">
            <Row>
              {
                twoImg.map((list,index)=>{
                  return (
                    <Col span={12} key={index} className='li-div'>
                      <a href={list.link}>
                        <div className="image-div">
                          <img src={require('../images/' + list.img)} alt=""/>
                        </div>
                        <div className="cover-frame">
                          <div className="title-div" dangerouslySetInnerHTML={{__html:list.title}}></div>
                          <div className="info-div">{list.info}</div>
                          <div className="more-div">更多内容<img src={require('../images/footer-arrow-ico2.svg')} alt=""/></div>
                        </div>
                      </a>
                    </Col>
                  )
                })
              }
            </Row>
          </div>
          <div className="big-img">
            <a href="/brand_story">
              <div className="image-div image1">
                <img src={require('../images/index-ad-img3-2.jpg')} alt=""/>
              </div>
              <div className="image-div image2">
                <img src={require('../images/index-ad-img3.jpg')} alt=""/>
              </div>
              <div className="image-div image3">
                <img src={require('../images/index-ad-img3-3.png')} alt=""/>
              </div>
              <div className="words-div">
                <div className="title-div">品牌故事</div>
                <div className="info-div">
                  <p>坚持用传统石磨技法研磨上好丘陵黄芝麻，</p>
                  <p>得到的100%古法纯芝麻酱,也保持对食材的最纯粹严苛与用心</p>
                </div>
                <div className="more-div">
                  <span className="more-span">查看详情<img src={require('../images/footer-arrow-ico2.svg')} alt=""/></span>
                </div>
              </div>
            </a>
          </div>
          <div className="news-frame">
            <div className="frame-div">
              <Row>
                {
                  this.state.newsArr.map((list,index)=>{
                    return (
                      <Col span={12} key={index}>
                        <div className="li-div">
                          <a href={list.link_url}>
                            <div className="link-div">
                              <div className="image-div">
                                <img src={list.image} alt=""/>
                              </div>
                              <div className="words-div">
                                <div className="title-div">{list.title}</div>
                                <div className="more-div">{index === 0 ? '更多美食' : '更多活动'}<img src={require('../images/footer-arrow-ico2.svg')} alt=""/></div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </Col>
                    )
                  })
                }
              </Row>
            </div>
          </div>
          <div className="right-video" onClick={()=>this.showVideo()}>
            <div className="image-frame">
              <div className="image-div">
                <img src={require('../images/index-video-img3.png')} alt=""/>
              </div>
              <div className="triangle-div">
                <img src={require('../images/index-video-img1.png')} alt=""/>
              </div>
            </div>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}  
export default Home
