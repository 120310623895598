import React from 'react'
import $$ from 'cmn-utils';
import { ActivityIndicator } from 'antd-mobile';
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import '../styles/online_registration.less';

const topBannerObj = {
  bgImg: 'online-registration-banner1.png',
  title: '在线报名',
  info: '若您有意加盟，请认真填写真实信息',
}
class OnlineRegistration extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      name:'',
      phone:'',
      email:'',
      animating: false,
      toastStr: '',
      addressArr: [],
      provinceArr: [],
      cityArr: [],
      provinceCurrent: '',
      cityCurrent: '',
		}
  }
  componentWillUnmount() {
    clearTimeout(this.closeTimer);
  }
	componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
    $$.get('./pca.json')
    .then(data => {
      let provinceArr = [];
      for (const province in data) {
        provinceArr.push(province);
      }
      this.setState({
        provinceArr,
        addressArr: data,
      })
    })
  }

  submitClick(){
    let toastStr = '';
    let phoneRe = /^(((13[0-9]{1})|(15[0-9]{1})|(17[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
    if(!this.state.name){
      toastStr = '姓名不能为空';
      this.showToast(toastStr);
      return false;
    }
    if(!phoneRe.test(this.state.phone)){
      toastStr = '请填写正确的手机号码';
      this.showToast(toastStr);
      return false;
    }
    $$.post('api/home/partner/create',{username:this.state.name, mobile:this.state.phone, email:this.state.email},{
      credentials:'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }, 
    })
    .then(data => {
      console.log(data);
      if(data.ret === 200){
        toastStr = data.msg;
        this.showToast(toastStr);
      }
    })
  }
  showToast = (toastStr) => {
    this.setState({ 
      toastStr,
      animating: !this.state.animating,
    });
    this.closeTimer = setTimeout(() => {
      this.setState({ animating: !this.state.animating });
    }, 1000);
  }
  provinceChange(e){
    let cityArr = [];
    for (const province in this.state.addressArr) {
      if(e.target.value === province){
        for (const city in this.state.addressArr[province]) {
          cityArr.push(city);
        }
      }
    }
    this.setState({
      cityArr,
      provinceCurrent: e.target.value,
    })
  }
  cityChange(e){
    this.setState({
      cityCurrent: e.target.value,
    })
  }
	render() {
	  return (
      <div className='online-registration' id='online-registration'>
        <HeadModle value='4' keyValue='setting:4_4'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="top-nav">
            <ul className="clear">
              <li className="fl-l complete">
                <div className="num-div fl-l">1</div>
                <div className="words-div fl-l">联系信息</div>
              </li>
              <li className="fl-l">
                <div className="num-div fl-l">2</div>
                <div className="words-div fl-l">门店信息</div>
              </li>
              <li className="fl-l">
                <div className="num-div fl-l">3</div>
                <div className="words-div fl-l">门店信息</div>
              </li>
            </ul>
          </div>
          <div className="form-frame">
            <div className="frame-div">
              <div className="form-head">请填写申请加盟信息<span>（必填，若您有意加盟绝味，请认真填写真实信息）</span></div>
              <div className="form-content">
                <div className="frame-content">
                  <ul>
                    <li className="clear">
                      <div className="li-left fl-l">联系人</div>
                      <div className="li-right fl-r">
                        <input type="text" className="text-input" value={this.state.name} onChange={(e)=>this.setState({name: e.target.value})} placeholder="方便我联系您" />
                      </div>
                    </li>
                    <li className="clear">
                      <div className="li-left fl-l">联系手机号</div>
                      <div className="li-right fl-r">
                        <input type="number" className="text-input" value={this.state.phone} onChange={(e)=>this.setState({phone: e.target.value})} placeholder="方便我联系您" />
                      </div>
                    </li>
                    <li className="clear">
                      <div className="li-left fl-l">申请城市</div>
                      <div className="li-right fl-r">
                        <select name="province" onChange={(e)=>this.provinceChange(e)} value={this.state.provinceCurrent}>
                          <option value="请选择省份">请选择省份</option>
                          {!!this.state.provinceArr && this.state.provinceArr.map((province,index)=>{
                            return (
                              <option key={index} value={province}>{province}</option>
                            );
                          })}
                        </select>
                        <select name="city" onChange={(e)=>this.cityChange(e)} value={this.state.cityCurrent}>
                          <option value="请选择城市">请选择城市</option>
                          {!!this.state.cityArr && this.state.cityArr.map((city,index)=>{
                            return (
                              <option key={index} value={city}>{city}</option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                    <li className="clear">
                      <div className="li-left fl-l">身份证号码</div>
                      <div className="li-right fl-r">
                        <input type="text" className="text-input" value={this.state.email} onChange={(e)=>this.setState({email: e.target.value})} placeholder="用于信息审核" />
                      </div>
                    </li>
                  </ul>
                  <div className="btn-div">
                    <button className="submit-btn" type="button" onClick={()=>this.submitClick()}>下一步</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterModule></FooterModule>
          <div><ActivityIndicator toast text={this.state.toastStr} animating={this.state.animating} /></div>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}

export default OnlineRegistration
