import React from 'react'
import $$ from 'cmn-utils';
import { Row, Col } from 'antd';
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import '../styles/features_surface.less';

// const foodTypeArr = [
//   {img: 'han-taste-snacks-ad1.png',title:'葱油饼',},
//   {img: 'han-taste-snacks-ad2.png',title:'红糖糍粑'},
//   {img: 'han-taste-snacks-ad1.png',title:'萝卜牛腩面粉面'},
//   {img: 'han-taste-snacks-ad2.png',title:'炸酱面'},
//   {img: 'han-taste-snacks-ad1.png',title:'牛肉粉'},
//   {img: 'han-taste-snacks-ad2.png',title:'三鲜汤面'},
// ]
class HanTasteSnacks extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      foodTypeArr: [],
		}
	}
	componentDidMount(){
    $$.post('/api/home/product/search',{cat_id:30},{
      credentials:'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }, 
    })
    .then(data => {
      this.setState({
        foodTypeArr: data.data.list,
      });
    })
	}
	render() {
    return (
      <div className='features-surface han-taste-snacks' id='features-surface'>
        <HeadModle value='2' keyValue='setting:2_4'></HeadModle>
        <div className="features-banner">
          <div className="bg-div"></div>
          <div className="words-div">
            <div className="title-div">汉味小吃</div>
          </div>
        </div>
        <div className="food-type">
          <div className="frame-div">
            <Row>
              {this.state.foodTypeArr.map((list,index)=>{
                return (
                  <Col span={8} key={index} className="col-frame">
                    <div className="col-div">
                      <div className="image-div">
                        <img src={list.icon} alt=""/>
                      </div>
                      <div className="words-div">{list.product_name}</div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <FooterModule></FooterModule>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}
export default HanTasteSnacks
