import React from 'react'
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'
import HeadModle from '../components/HeadModle'
import FooterModule from '../components/FooterModule'
import RightSuspension from '../components/RightSuspension'
import BannerModule from '../components/BannerModule'
import '../styles/brand_story.less'

const topBannerObj = {
  bgImg: 'brand-story-banner1.jpg',
  title: '常青麦香园故事',
  info: '常青麦香园，热干面的三代传承',
}
const meaningArr1 = ["brand-story-meaning1.png","brand-story-meaning2.png","brand-story-meaning3.png","brand-story-meaning4.png","brand-story-meaning5.png","brand-story-meaning6.png","brand-story-meaning7.png","brand-story-meaning8.png","brand-story-meaning9.png","brand-story-meaning10.png","brand-story-meaning11.png","brand-story-meaning12.png",]
const meaningArr2 = ["brand-story-wap1.jpg","brand-story-wap2.jpg","brand-story-wap3.jpg","brand-story-wap4.jpg","brand-story-wap5.jpg","brand-story-wap6.jpg","brand-story-wap7.jpg","brand-story-wap8.jpg","brand-story-wap9.jpg","brand-story-wap10.jpg",]
class HanTasteFood extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      meaningArr: [],
      direction: 'horizontal',
		}
  }
  componentWillUnmount() {
		if (this.swiper) { // 销毁swiper
			this.swiper.destroy();
		}
	}
	componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
    this.setState({
      meaningArr: (document.documentElement.clientWidth > 480) ? meaningArr1 : meaningArr2,
      direction: (document.documentElement.clientWidth > 480) ? 'horizontal' : 'vertical',
    })
  }
  meaningShow(){
    document.getElementById('meaning-frame').style.display = 'block';
    document.getElementById('brand-main').className = 'blur';
    if(this.swiper){
			this.swiper.slideTo(0, 0);
			this.swiper.destroy();
			this.swiper = null;
    }
    const $this = this;
		this.swiper = new Swiper('.meaning-swiper', {
      direction: $this.state.direction,
			loop:true,
			pagination: {
				el: '.meaning-pagination',
				clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"><i></i></span>';
        },
			},
			// autoplay: {
      //   delay: 2500,
      //   disableOnInteraction: false,
			// },
      navigation: {
        nextEl: '.meaning-next',
        prevEl: '.meaning-prev',
      },
      on:{
        slideChange: function(){
          let currentNo = 1;
          if(this.activeIndex % 12 === 0){
            currentNo = 12;
          }else{
            currentNo = this.activeIndex % 12;
          }
          $this.setState({currentNo});
        },
      },
		});
  }
  meaningHide(){
    document.getElementById('meaning-frame').style.display = 'none';
    document.getElementById('brand-main').className = ' ';
  }
  meaningHide2(){
    if(document.documentElement.clientWidth <= 480){
      document.getElementById('meaning-frame').style.display = 'none';
      document.getElementById('brand-main').className = ' ';
    }
  }
	render() {
	  return (
      <div className='brand-story' id='brand-story'>
        <div id="brand-main">
          <HeadModle value='1' keyValue='setting:1_3'></HeadModle>
          <div id="body-frame">
            <BannerModule value={topBannerObj}></BannerModule>
            <div className="top-introduction">
              <div className="frame-div">常青麦香园文化，常青麦香园故事</div>
            </div>
            <div className="story-frame">
              <div className="frame-div">
                <ul>
                  <li>
                    <a href="/inherit">
                      <div className="li-div">
                        <div className="image-div">
                          <img src={require('../images/brand-story-img2.png')} alt=""/>
                        </div>
                        <div className="words-div">
                          <div className="title-div">风雨历程</div>
                          <div className="info-div">李亚娟与热干面的故事</div>
                          <div className="more-div">查看详情<img src={require('../images/footer-arrow-ico2.svg')} alt=""/></div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div className="li-div" onClick={()=>this.meaningShow()}>
                      <div className="image-div">
                        <img src={require('../images/brand-story-img3.png')} alt=""/>
                      </div>
                      <div className="words-div">
                        <div className="title-div">热干面的奥义</div>
                        <div className="info-div">匠心注入 热爱到底</div>
                        <div className="more-div">查看详情<img src={require('../images/footer-arrow-ico2.svg')} alt=""/></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="/honor">
                      <div className="li-div">
                        <div className="image-div">
                          <img src={require('../images/brand-story-img4.png')} alt=""/>
                        </div>
                        <div className="words-div">
                          <div className="title-div">卓越成果</div>
                          <div className="info-div">坚持优秀品质，呈现多样美味，汉味小吃的传承发扬者</div>
                          <div className="more-div">查看详情<img src={require('../images/footer-arrow-ico2.svg')} alt=""/></div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <FooterModule></FooterModule>
          </div>
          <RightSuspension></RightSuspension>
        </div>
        <div className="meaning-frame" id="meaning-frame">
          <div className="frame-div">
            <div className="close-div" onClick={()=>this.meaningHide()}>
              <span className="close-span">×</span>
            </div>
            <div className="swiper-container meaning-swiper" onClick={()=>this.meaningHide2()}>
              <div className="swiper-wrapper">
                {!!this.state.meaningArr && this.state.meaningArr.map((list,index)=>{
                  return (
                    <div className={"swiper-slide slide" + index} key={index}>
                      <img src={require('../images/' + list)} alt=""/>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="swiper-pagination meaning-pagination"></div>
            <div className="swiper-button-next meaning-next"></div>
            <div className="swiper-button-prev meaning-prev"></div>
            <div className="number-div">{this.state.currentNo}/12</div>
          </div>
        </div>
      </div>
    )
  }
}

export default HanTasteFood
