import React from 'react'
import $$ from 'cmn-utils';
import { Pagination, Spin, Icon } from 'antd';
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import utils from '../utils/func';
import '../styles/news_activity.less';

const labelArr = [
  {name:'企业动态',value:'39'},
  {name:'媒体报道',value:'40'},
  {name:'市场活动',value:'41'},
  {name:'培训活动',value:'42'},
];
const topBannerObj = {
  bgImg: 'news-activity-banner1.png',
  title: '新闻活动',
  info: '了解常青麦香园的最新动态',
}
class NewsActivity extends React.Component {
  constructor(props){
		super(props);
		this.state = {
      labelCurrent: '40',
      newsArr:[],
      totalcount: 0,
      spinState: false,
		}
  }
  labelClick(i){
    this.searchFun(i);
    this.setState({
      labelCurrent: i,
      newsArr: [],
      totalcount: 0,
    });
  }
  onChange(page, pageSize){
    console.log(page, pageSize);
    this.searchFun(this.state.labelCurrent,page, pageSize);
  }
  searchFun(cat_id,page=1,pagenum=10){
    this.setState({
      spinState: true,
    });
    $$.post("api/home/news/search",{cat_id,page,pagenum},{
      credentials:'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }, 
    })
    .then(data => {
      console.log(data);
      this.setState({
        newsArr: data.data.list,
        totalcount: data.data.totalcount,
        spinState: false,
      });
    })
  }
	componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
    this.searchFun(this.state.labelCurrent);
	}
  render() {
    return (
      <div className='news-activity' id='news-activity'>
        <HeadModle value='1' keyValue='setting:1_5'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="label-frame">
            <div className="frame-div">
              <ul className="clear">
                {
                  labelArr.map((list,index)=>{
                    return (
                      <li key={list.value} className={this.state.labelCurrent === list.value ? "fl-l current" : "fl-l"}>
                        <button type='button' onClick={()=>this.labelClick(list.value)}>{list.name}</button>
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          </div>
          <div className="news-frame">
            <div className="frame-div">
              <div className="row-div">
                {
                  this.state.newsArr.map((list,index)=>{
                    return (
                      <div className="col-frame" key={index}>
                        <div className="col-div box-shadow">
                          <a href={list.link_url === ''?'news_details?' + list.id:list.link_url} className="col-link" target="_blank">
                            <div className="image-div">
                              <img src={list.image} alt=""/>
                            </div>
                            <div className="words-div">
                              <div className="title-div" >{list.title}</div>
                              <div className="bottom-info clear">
                                <div className="time-div">发布时间：{utils.getdate(list.uptime*1000)}</div>
                                {/* <div className="details-div fl-r">
                                  查看详情<img src={require('../images/footer-arrow-ico2.svg')} alt=""/>
                                </div> */}
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
              <div className="spin-div">
                <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} tip="Loading..." spinning={this.state.spinState}  />
              </div>
              <div className="pagination">
                <Pagination onChange={(page, pageSize)=>this.onChange(page, pageSize)} total={this.state.totalcount} defaultCurrent={1} />
              </div>
            </div>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    );
  }
}

export default NewsActivity
