import React from 'react'
import $$ from 'cmn-utils';
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import RightSuspension from '../components/RightSuspension';
import BannerModule from '../components/BannerModule';
import SwiperFreshFactory from '../components/SwiperFreshFactory';
import '../styles/school_profile.less';

// const banner = [
//   {img:'school-profile-banner4.png',link:'https://ant.design',title:'相关工厂场景介绍相关文字相关文字相关文字相关文字相关文字相关文字相关文字相关文字'},
//   {img:'school-profile-banner4.png',link:'https://ant.design',title:'相关工厂场景介绍相关文字相关文字相关文字相关文字相关文字相关文字相关文字相关文字'},
//   {img:'school-profile-banner4.png',link:'https://ant.design',title:'相关工厂场景介绍相关文字相关文字相关文字相关文字相关文字相关文字相关文字相关文字'},
// ]
const topBannerObj = {
  bgImg: 'school-profile-banner1.png',
  title: '商学院介绍',
  info: '高度重视职业教育。一一习近平<br />中国不缺大学，缺职业技能学校。一一马云<br />大众创业，万众创新。一一李克强',
}

class SchoolProfile extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      bannerArr: [],
		}
	}
  componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
    $$.post('api/home/banner/search',{type:2},{
      credentials:'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }, 
    })
    .then(data => {
      this.setState({
        bannerArr: data.data.list,
      });
    })
  }
  render() {
    return(
      <div className='school-profile' id='school-profile'>
        <HeadModle value='3' keyValue='setting:3_1'></HeadModle>
        <div id="body-frame">
          <BannerModule value={topBannerObj}></BannerModule>
          <div className="top-introduction top-introduction1">
            <div className="frame-div">领先的武汉热干面培训学校</div>
            <div className="info-div">武汉热干面创业孵化园 构筑完善的热干面产业服务体系</div>
          </div>
          <div className="top-news">
            {/* <div className="words">
              <p>湖北常青麦香园商学院是经国家劳动部门批准成立的全国第一所热干面产业学校。校区<b>建面达5000</b>㎡，是集<b>“生产、教学、研发、创业创新”</b>功能于一体，授中式面点师、中式烹调师、武汉热干面品牌运营管理、市场营销等小餐饮创业专业人才的职业教育基地。</p>
              <p>旨在从<b>“培训、生产、研发、经营、创业、加盟、供应链”</b>等方面全方位进行谋划布局和战略构想。与将从事热干面行业的人员、技术、人才得以深入交流合作，建立健全武汉热干面产业服务体系，发展全国连锁品牌连锁门店、从事教育培训热干面技术人员和营运人才输出，构建食材原产地到中央工厂集采供应链、从单品研发到标准生产、从技能培训到连锁运营。</p>
            </div> */}
            {/* <div className="words">
              <p>湖北常青麦香园商学院是经国家劳动部门批准成立的全国第一所热干面产业学校。校区<b>建面达5000</b>㎡，是集<b>“教学、研发、创业创新”</b>功能于一体，授中式面点师、中式烹调师、武汉热干面品牌运营管理、市场营销等小餐饮创业专业人才的职业教育基地。</p>
              <p>旨在从<b>“培训、经营、创业、加盟、供应链”</b>等方面全方位进行谋划布局和战略构想。与将从事热干面行业的人员、技术、人才得以深入交流合作，建立健全武汉热干面产业服务体系，发展全国连锁品牌连锁门店、从事教育培训热干面技术人员和营运人才输出，构建食材原产地到中央工厂集采供应链、从单品研发到标准生产、从技能培训到连锁运营。</p>
            </div> */}
            <div className="words">
              <p>湖北常青麦香园商学院是由热干面领先品牌常青麦香园公司倾力打造的培训基地，综合建设面积达5000㎡。商学院从学员开店切实需求出发，除教授热干粉面、牛肉粉面、蛋酒面窝等武汉特色小吃制作技艺外，还向学员传授选址运营、市场营销等管理知识，让学员既能充分适应岗位需求，又能掌握基本的企业管理知识。</p>
              <p>培训基地与从事热干面行业的人才深入交流合作，建立健全武汉热干面产业服务体系，发展全国连锁品牌连锁门店。</p>
            </div>
            <div className="images">
              <div className="left-image">
                <img src={require('../images/school-profile-ad1.png')} alt=""/>
              </div>
            </div>
            <div className="top-bottom">
              <div className="li-div">
                <div className="ico-div">
                  <img src={require('../images/school-profile-ico2.png')} alt=""/>
                </div>
                <div className="words-div">
                  <div className="title-div">办学理念</div>
                  <div className="info-div">为持续奋斗者创造美好生活<br/>让天下没有难做的小餐饮</div>
                </div>
              </div>
              <div className="li-div">
                <div className="ico-div">
                  <img src={require('../images/school-profile-ico3.png')} alt=""/>
                </div>
                <div className="words-div">
                  <div className="title-div">服务对象</div>
                  <div className="info-div">企业员工<br/>加盟事业合伙人<br/>小餐饮行业创业者</div>
                </div>
              </div>
              <div className="li-div">
                <div className="ico-div">
                  <img src={require('../images/school-profile-ico4.png')} alt=""/>
                </div>
                <div className="words-div">
                  <div className="title-div">业务范围</div>
                  <div className="info-div">产品研发、技能培训<br/>综合人才输出、经营管理<br/>原材料供应、集成采购供应链</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="principle-div">
            <div className="frame-div">
              <div className="image-div">
                <img src={require('../images/school-profile-ico1.png')} alt=""/>
              </div>
              <div className="words-div">从培训、生产、研发、经营、创业、加盟、供应链等方面全方位进行谋划布局和战略构想，让想从事热干面行业的人员、技术、人才得以深入交流合作，建立健全武汉热干面产业服务体系，发展全国连锁、职业培训、人才输出交流。</div>
            </div>
          </div> */}
          {/* <div className="top-introduction top-introduction2">
            <div className="frame-div">硬件设施</div>
            <div className="info-div">武汉热干面创业孵化园 构筑完善的热干面产业服务体系</div>
          </div> */}
          <div className="profile-frame">
            <div className="frame-div">
              {/* <div className="top-profile">硬件设施：商学院设有理论培训教室大小共4间，可同时容纳400人进行理论授课学习；产品品鉴、技术实操教室6间可同时开展逾20种产品的实操技术授课，高清同步播放屏，保证授课细节实时播放，技术标准统一； 常青麦香园商学院，以汉味美食文化传播和技术创新为核心，坚持不懈，持续为新生代小餐饮创业者付出自己的努力。 </div> */}
              <div className="profile-list">
                <ul>
                  <li>
                    <div className="li-main">
                      <div className="image-div">
                        <img src={require('../images/school-profile-ad2.jpg')} alt=""/>
                      </div>
                      <div className="words-div">
                        <div className="title-div">企业员工：培养出技术、运营、销售职能管理综合人才</div>
                        <div className="info-div">
                          <p>面对激烈的市场竞争，单兵作战自我学习是远远不够的。常青麦香园商学院联合中国餐饮协会、<b>中国东方美食杂志、海底捞咨询、联合利华餐饮顾问、华商书院等国内外权威餐饮机构</b>，聘请优秀的讲师针对员工，进行集中内训。</p>
                          <p>每期“特训营”计划，旨在从企业文化到门店技巧，从产品实操到顾客心理，课程设计极具互动性、实战性，提升员工综合素质同时突破自我设限，通过商学院平台培养<b>技术骨干、运营管理、销售管理、餐饮职能管理综合人才</b></p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="li-main">
                      <div className="image-div">
                        <img src={require('../images/school-profile-ad3.jpg')} alt=""/>
                      </div>
                      <div className="words-div">
                        <div className="title-div">事业合伙人：提升综合竞争力增强持续盈利力</div>
                        <div className="info-div">
                          <p>“授人以鱼，不如授人以渔”，针对加盟门店进行从选址--单店行销--门店经营的理论分享，针对老加盟商进行 “回炉再造” 的系统培训。</p>
                          <p>企业文化体验之旅，同心才能同行。通过对新品类、新产品学习，给顾客全新体验。实现老店创新收板块，增加单店营业额。我们的责任不仅是帮创业者成功开店，更是确保加盟商在快速变化的市场中能够持续盈利。</p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="li-main">
                      <div className="image-div">
                        <img src={require('../images/school-profile-ad4.jpg')} alt=""/>
                      </div>
                      <div className="words-div">
                        <div className="title-div">让天下没有难做的小餐饮生意</div>
                        <div className="info-div">
                          <p>正如李亚娟所说:“世上最快捷、踏实的工作就是有一门好厨艺”。 我们将逐步形成以智慧鲜食工厂为生产力、以产品研发中心为产品推动力、以热干面商学院为培养力的“3+1”模型，打造企业核心竞争力体系，实现“百城万店”的战略目标。帮助更多中小创业者完成“开一个面店·养一个小家”的梦想。</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="banner-div">
            <SwiperFreshFactory value={this.state.bannerArr}></SwiperFreshFactory>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}

export default SchoolProfile
