import React from 'react'
import HeadModle from '../components/HeadModle'
import FooterModule from '../components/FooterModule'
import RightSuspension from '../components/RightSuspension'
import '../styles/inherit.less'

class HanTasteFood extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		}
  }
  
	componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#f3f3f3";
  }
	render() {
	  return (
      <div className='inherit' id='inherit'>
        <HeadModle value='1' keyValue='setting:1_3'></HeadModle>
        <div className="detail-banner">
          <div className="bg-div"></div>
          <div className="words-div">
            <div className="title-div">风雨历程</div>
          </div>
        </div>
        <div className="inherit-detail">
          <div className="inherit-top">
            <ul>
              <li>
                <div className="li-main">
                  <div className="image-div">
                    <img src={require('../images/inherit-img1.png')} alt=""/>
                  </div>
                  <div className="words-div">
                    <p>2010年以前常青麦香园创始人李亚娟女士一直在做自己的美食馆。一次机缘巧合，拜师武汉蔡林记创始人蔡明伟的儿子，蔡汉文老先生。蔡明伟拜年诞辰之际，蔡汉文老先生收了李亚娟为嫡传弟子，毫无保留的将自己的家传“真经”——“真材实料，精工细作”，传授给了她，<span>李亚娟由此成为热干面创始人的第三代传人。 </span></p>
                    <p>历经多年，从门店形象、门店运营到产品品质把控，中央厨房建立到连锁经营模式运作，李亚娟将武汉人的“过早”文化更新换代，趋于更加贴合市场需求的方向上发展.现品牌跨越湖北、湖南、广东、山东、山西、河南等多个省份，60余座城市。全国门店数量已经达<span>900余家</span>，日销售热干面<span>50万碗</span>，成为跨区域热干面连锁发展的先驱与标干。 </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="inherit-bottom">
            <ul>
              <li>
                <div className="li-main">
                  <div className="image-div">
                    <img src={require('../images/inherit-img3.png')} alt=""/>
                  </div>
                  <div className="words-div">
                    <div className="year-div">1993-2010<span>成长期</span></div>
                    <div className="title-div">第一代门店</div>
                    <div className="info-div">常青花园汉味美食城</div>
                  </div>
                </div>
              </li>
              <li>
                <div className="li-main">
                  <div className="image-div">
                    <img src={require('../images/inherit-img4.png')} alt=""/>
                  </div>
                  <div className="words-div">
                    <div className="year-div">2011-2012<span>发展期</span></div>
                    <div className="title-div">第二代门店</div>
                    <div className="info-div">从亲戚朋友加盟开始，醒目的门头在汉开店100多家</div>
                  </div>
                </div>
              </li>
              <li>
                <div className="li-main">
                  <div className="image-div">
                    <img src={require('../images/inherit-img5.png')} alt=""/>
                  </div>
                  <div className="words-div">
                    <div className="year-div">2012-2015<span>精细期</span></div>
                    <div className="title-div">第三代门店</div>
                    <div className="info-div">品牌标准化、规划化的升级年，靠此门店跨省开店900家</div>
                  </div>
                </div>
              </li>
              <li>
                <div className="li-main">
                  <div className="image-div">
                    <img src={require('../images/inherit-img6.png')} alt=""/>
                  </div>
                  <div className="words-div">
                    <div className="year-div">2016-2018<span>精益期</span></div>
                    <div className="title-div">第四代门店</div>
                    <div className="info-div">每一次的品牌升级都能引领着整个行业的升、跟随，也被业内评为“用洋快餐的标准玩转汉味小吃的搅局者、创新者！”</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <FooterModule></FooterModule>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}

export default HanTasteFood
