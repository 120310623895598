import React from 'react';
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'
import '../styles/SwiperFreshFactory.less'

class About extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		}
	}
	componentWillUnmount() {
		if (this.swiper) { // 销毁swiper
			this.swiper.destroy();
		}
	}
	componentDidMount(){
	}
	componentDidUpdate(){
		if(this.swiper){
			this.swiper.slideTo(0, 0);
			this.swiper.destroy();
			this.swiper = null;
		}
		this.swiper = new Swiper('.fresh-factory-swiper', {
			loop:true,
			pagination: {
				el: '.banner-pagination',
				type: 'fraction',
				clickable: true,
			},
			autoplay: {
        delay: 2500,
        disableOnInteraction: false,
			},
      navigation: {
        nextEl: '.banner-next',
        prevEl: '.banner-prev',
      },
		});
	}
	render() {
	  return (
			<div>
				<div className="swiper-container fresh-factory-swiper">
					<div className="swiper-wrapper">
						{
							this.props.value.length && this.props.value.map((item, index) => {
								return(
									<div key={index} className="swiper-slide">
										<a href={item.link}>
											<div className="image-div">
												<img src={item.file} alt=""/>
											</div>
											<div className="words-div">{item.title}</div>
										</a>
									</div>
								)
							})
						}
					</div>
					<div className="swiper-pagination banner-pagination"></div>
					<div className="swiper-button-next banner-next"></div>
					<div className="swiper-button-prev banner-prev"></div>
		 		</div>
			</div>
		)
	}
}

export default About;