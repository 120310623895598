import React from 'react';
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'

class About extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		}
	}
	componentWillUnmount() {
		if (this.swiper) { // 销毁swiper
			this.swiper.destroy();
		}
	}
	componentDidMount(){
	}
	componentDidUpdate(){
		if(this.swiper){
			this.swiper.slideTo(0, 0);
			this.swiper.destroy();
			this.swiper = null;
		}
		this.swiper = new Swiper('.banner-swiper', {
			loop:true,
			pagination: {
				el: '.banner-pagination',
				// type: 'fraction',
				clickable: true,
			},
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
		});
	}
	render() {
		console.log(this.props.value);
	  return (
			<div>
				<div className="swiper-container banner-swiper">
					<div className="swiper-wrapper">
						{
							this.props.value.length && this.props.value.map((item, index) => {
								return(
									<div key={index} className="swiper-slide">
										<a href={item.link}>
											<img src={item.file} alt=""/>
										</a>
									</div>
								)
							})
						}
					</div>
					<div className="swiper-pagination banner-pagination"></div>
		 		</div>
			</div>
		)
	}
}

export default About;