import React from 'react'
import { Select } from 'antd';
import { Map, Marker, InfoWindow } from 'react-amap';
import $$ from 'cmn-utils';
import HeadModle from '../components/HeadModle';
import FooterModule from '../components/FooterModule';
import SwiperFreshFactory from '../components/SwiperFreshFactory';
import RightSuspension from '../components/RightSuspension';
import '../styles/store_display.less';
const Option = Select.Option;

// const banner = [
//   {img:'store-display-banner4.png',link:'https://ant.design',title:'相关工厂场景介绍相关文字相关文字相关文字相关文字相关文字相关文字相关文字相关文字'},
//   {img:'store-display-banner4.png',link:'https://ant.design',title:'相关工厂场景介绍相关文字相关文字相关文字相关文字相关文字相关文字相关文字相关文字'},
//   {img:'store-display-banner4.png',link:'https://ant.design',title:'相关工厂场景介绍相关文字相关文字相关文字相关文字相关文字相关文字相关文字相关文字'},
// ]
const storeObj = [
  {province:'北京市',city:[{city:'北京市',district:[{district:'b1',store:['门店1','门店2','门店3']}]}]},
  {province:'天津市',city:[{city:'天津市',district:[{district:'t1',store:['门店1','门店2','门店3']}]}]},
  {province:'上海市',city:[{city:'上海市',district:[{district:'s1',store:['门店1','门店2','门店3']}]}]},
  {province:'江苏省',city:[{city:'江苏市',district:[{district:'j1',store:['门店1','门店2','门店3']}]},{city:'江苏市2',district:[{district:'j2',store:['门店1','门店2','门店3']}]}]},
  {province:'浙江省',city:[{city:'杭州市',district:[{district:'z1',store:['门店1','门店2','门店3']}]},{city:'浙江市2',district:[{district:'z2',store:['门店1','门店2','门店3']}]}]},
  {province:'江西省',city:[{city:'江西市',district:[{district:'j1',store:['门店1','门店2','门店3']}]},{city:'江西市2',district:[{district:'j2',store:['门店1','门店2','门店3']}]}]},
  {province:'河南省',city:[{city:'南京市',district:[{district:'h1',store:['门店1','门店2','门店3']}]},{city:'河南市2',district:[{district:'h2',store:['门店1','门店2','门店3']}]}]},
  {province:'湖北省',city:[{city:'武汉市',district:[{district:'h1',store:['门店1','门店2','门店3']}]},{city:'湖北市2',district:[{district:'h2',store:['门店1','门店2','门店3']}]}]},
  {province:'湖南省',city:[{city:'长沙市',district:[{district:'h1',store:['门店1','门店2','门店3']}]},{city:'湖南市2',district:[{district:'h2',store:['门店1','门店2','门店3']}]}]},
  {province:'广东省',city:[{city:'深圳市',district:[{district:'g1',store:['门店1','门店2','门店3']}]},{city:'广东市2',district:[{district:'g2',store:['门店1','门店2','门店3']}]}]},
  {province:'重庆市',city:[{city:'重庆市',district:[{district:'c1',store:['门店1','门店2','门店3']}]},{city:'重庆市2',district:[{district:'c2',store:['门店1','门店2','门店3']}]}]},
  {province:'四川省',city:[{city:'四川市',district:[{district:'s1',store:['门店1','门店2','门店3']}]},{city:'四川市2',district:[{district:'s2',store:['门店1','门店2','门店3']}]}]},
  {province:'河北省',city:[{city:'河北市',district:[{district:'h1',store:['门店1','门店2','门店3']}]},{city:'河北市2',district:[{district:'h2',store:['门店1','门店2','门店3']}]}]},
  {province:'山东省',city:[{city:'山东市',district:[{district:'s1',store:['门店1','门店2','门店3']}]},{city:'山东市2',district:[{district:'s2',store:['门店1','门店2','门店3']}]}]},
  {province:'安徽省',city:[{city:'安徽市',district:[{district:'a1',store:['门店1','门店2','门店3']}]},{city:'安徽市2',district:[{district:'a2',store:['门店1','门店2','门店3']}]}]},
  {province:'福建省',city:[{city:'福建市',district:[{district:'f1',store:['门店1','门店2','门店3']}]},{city:'福建市2',district:[{district:'f2',store:['门店1','门店2','门店3']}]}]},
  {province:'陕西省',city:[{city:'陕西市',district:[{district:'s1',store:['门店1','门店2','门店3']}]},{city:'陕西市2',district:[{district:'s2',store:['门店1','门店2','门店3']}]}]},
]
const usedCityList = ['上海市','南京市','杭州市','武汉市','长沙市','深圳市',]
const styleA = {
  width: '1.4rem',
  height: '2.2rem',
}

class SchoolProfile extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      address:'',
      areaLevel:2,
      btnLevel:1,
      areaArr:usedCityList,
      provinceBtn:'省/直辖市',
      cityBtn:'',
      district:'',
      storeArr:[],
      position:{longitude: 114.31620010, latitude: 30.58108413},
      currentArea: '',
      storeCurrent: '',
      bannerArr: [],
		}
    this.mapPlugins = ['ToolBar'];
    this.mapCenter = {longitude: 120, latitude: 35};
    this.markerPosition = this.state.position;
	}
  componentDidMount(){
    document.querySelector('body').style.backgroundColor = "#fff";
    const $this = this;
    document.querySelector('#map-frame').style.height = document.documentElement.clientHeight + 'px';
    $$.get('https://restapi.amap.com/v3/ip?key=83b9e4426586bd279c20322be19c06dd',{},{credentials:'omit',})
    .then(resp => {
      $$.get("https://restapi.amap.com/v3/geocode/geo?key=83b9e4426586bd279c20322be19c06dd&address="+resp.province+resp.city,{},{credentials:'omit',})
      .then(resp => {
        const location = resp.geocodes[0].location;
        $$.get("https://restapi.amap.com/v3/geocode/regeo?key=83b9e4426586bd279c20322be19c06dd&location="+location,{},{credentials:'omit',})
        .then(resp => {
          const address = resp.regeocode.addressComponent;
          $this.setState({
            address: address.province +'-'+ address.city +'-'+ address.district,
            provinceBtn:address.province,
            cityBtn:address.city,
            district:address.district,
            btnLevel:3,
            areaLevel:3,
            areaArr:this.searchDistrict(address.city),
            storeArr:this.searchStore(address.district),
            currentArea: address.district,
            storeCurrent: this.searchStore(address.district)[0],
          });
        })
      })
    })
    $$.post('api/home/banner/search',{type:3},{
      credentials:'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }, 
    })
    .then(data => {
      this.setState({
        bannerArr: data.data.list,
      });
    })
  }
  handleChange(value) {
    console.log(value);
    this.setState({
      storeCurrent: value,
    });
  }
  searchProvince(){
    let province = '';
    storeObj.map((list,index)=>{
      list.city.map((city,i)=>{
        if (city.city === this.state.cityBtn) {
          province = list.province;
        }
        return false;
      });
      return false;
    });
    return province;
  }
  searchCity(value){
    let cityArr = [];
    storeObj.map((list,index)=>{
      if (list.province === value) {
        list.city.map((city,i)=>{
          cityArr.push(city.city);
          return false;
        });
      }
      return false;
    });
    return cityArr;
  }
  searchDistrict(value){
    let districtArr = [];
    storeObj.map((list,index)=>{
      list.city.map((city,i)=>{
        if (city.city === value) {
          city.district.map((district,j)=>{
            districtArr.push(district.district);
            return false;
          });
        }
        return false;
      });
      return false;
    });
    return districtArr;
  }
  searchStore(value){
    let storeArr = [];
    storeObj.map((list,index)=>{
      list.city.map((city,i)=>{
        city.district.map((district,j)=>{
          if (district.district === value) {
            storeArr = district.store;
          }
          return false;
        });
        return false;
      });
      return false;
    });
    return storeArr;
  }
  firstChooseClick(i) {
    let areaArr = [];
    let provinceBtn = this.state.provinceBtn;
    let cityBtn = '';
    let areaLevel = 0;
    let currentArea = this.state.currentArea;
    if(i === 1){
      areaArr = usedCityList;
      provinceBtn = '省/直辖市';
      areaLevel = 2;
      currentArea = '';
    }else if(i === 2){
      storeObj.map((list,index)=>{
        areaArr.push(list.province);
        return false;
      });
      areaLevel = 1;
      currentArea = this.state.provinceBtn;
    }else{
      areaArr = this.state.areaArr;
      cityBtn = this.state.cityBtn;
      areaLevel = 3;
    }
    this.setState({
      areaArr,
      provinceBtn,
      cityBtn,
      areaLevel,
      currentArea,
      btnLevel: i,
    });
  }
  secondChooseClick(e){
    let { cityBtn, areaArr, areaLevel, provinceBtn, btnLevel, address, district, storeArr, currentArea, storeCurrent } = this.state;
    if (this.state.areaLevel === 1) {
      provinceBtn = e.target.value;
      areaLevel = 2;
      btnLevel = 2;
      areaArr = this.searchCity(provinceBtn);
    }else if (this.state.areaLevel === 2){
      cityBtn = e.target.value;
      areaLevel = 3;
      btnLevel = 3;
      areaArr = this.searchDistrict(cityBtn);
    }else{
      district = e.target.value;
      currentArea = district;
      address = this.searchProvince() +'-'+ cityBtn +'-'+ district;
      storeArr = this.searchStore(district);
      storeCurrent = storeArr[0];
    }
    this.setState({ cityBtn, areaArr, areaLevel, provinceBtn, btnLevel, address, district, storeArr, currentArea, storeCurrent });
  }
  render() {
    return(
      <div className='store-display' id='store-display'>
        <HeadModle value='4' keyValue='setting:4_3'></HeadModle>
        <div id="body-frame">
          <div className="store-frame">
            <div className="area-frame">
              <div className="title-div">门店分布</div>
              <div className="info-div">常青麦香园门店信息查询</div>
              <div className="address-div">
                <input type="text" value={this.state.address} disabled='disabled' />
              </div>
              <div className="choose-frame">
                <div className="top-choose">
                  <ul className="clear">
                    <li className={this.state.btnLevel === 1 ? "choose-li fl-l current" : "choose-li fl-l"}>
                      <button type='button' onClick={()=>this.firstChooseClick(1)}>常用城市</button>
                    </li>
                    <li className={this.state.btnLevel === 2 ? "choose-li fl-l current" : "choose-li fl-l"}>
                      <button type='button' onClick={()=>this.firstChooseClick(2)}>{this.state.provinceBtn}</button>
                    </li>
                    <li className={this.state.btnLevel === 3 ? "choose-li fl-l current" : "choose-li fl-l"}>
                      <button className={this.state.cityBtn ? "" : "hide"} type='button' onClick={()=>this.firstChooseClick(3)}>{this.state.cityBtn}</button>
                    </li>
                  </ul>
                </div>
                <div className="choose-area">
                  <ul className="clear">
                    {this.state.areaArr.map((list,index)=>{
                      return (
                        <li key={index} className={list===this.state.currentArea ? "fl-l current" : "fl-l"}>
                          <button type='button' value={list} onClick={(e)=>this.secondChooseClick(e)}>{list}</button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="store-div">
                <Select onChange={(value)=>this.handleChange(value)} value={this.state.storeCurrent} >
                  {this.state.storeArr.map((list,index)=>{
                    return (
                      <Option value={list} key={index} selected={index===0}>{list}</Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className="map-frame" id="map-frame">
              <Map
                plugins={this.mapPlugins}
                center={this.markerPosition}
                zoom={12}>
                <Marker position={this.markerPosition} render={()=>{return <div id="marker-ico" style={styleA}></div>}} />
                <InfoWindow
                  position={this.markerPosition}
                  visible='true'
                  offset={[14, -14]}
                >
                  <div style={{lineHeight: '2em'}}>
                    <div className="title-div">常青麦香园(武汉站店)</div>
                    <div className="address-div">XX一路5XX号中 XX3栋1-3-01号1F-34号商铺</div>
                    <div className="phone-div">4001717717</div>
                  </div>
                </InfoWindow>
              </Map>
            </div>
          </div>
          <div className="store-top">
            <div className="title-div">
              <p>3省市20余个中小城市<span className="yellow-span">1000</span>多家门店，<br/>日接待顾客<span className="yellow-span">70万</span>人次，<br/>日销热干面<span className="yellow-span">60万</span>碗</p>
            </div>
            <div className="image-div">
              <img src={require('../images/store-display-line.png')} alt="" className="bg-img"/>      
              <img src={require('../images/store-display-img1.png')} alt="" className="map-img" />
            </div>
          </div>
          <div className="banner-div">
            <div id="banner-div"></div>
            <SwiperFreshFactory value={this.state.bannerArr}></SwiperFreshFactory>
          </div>
          <FooterModule></FooterModule>
        </div>
        <RightSuspension></RightSuspension>
      </div>
    )
  }
}

export default SchoolProfile
